import React, {useEffect, Dispatch} from "react";
import {NavigateFunction, useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {UnknownAction} from "redux";
import Grid from "@mui/material/Grid";
import Joi from "joi";
import {Theme, useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import useForm, {IForm} from "../generics/useForm";
import {FormSchema} from "../../store/types/confType";
import {UserService} from "../../services/firebase";
import {AuthProps} from "../generics/useAuth";

const FORM_SCHEMA:FormSchema[]=[
    {
        id: "password",
        type: "password",
        label: "Code",
        joiOptions: Joi.string().required()
            .messages({
                "string.empty": "Required",
            }),
    },
    {
        id: "SUBMIT",
        type: "submit",
        label: "Sign in",
    },
];

interface SignInProps{
    auth: AuthProps
}

/**
 * SignIn
 * @return {React.ReactElement}
 */
function SignIn(props:SignInProps):React.ReactElement {
    const form:IForm=useForm(FORM_SCHEMA);
    const navigate:NavigateFunction=useNavigate();
    const theme:Theme=useTheme();
    const dispatch:Dispatch<UnknownAction>=useDispatch();
    const isMD:boolean=useMediaQuery(theme.breakpoints.down("md"));

    useEffect(() => { if (props.auth.user) navigate("/"); });

    /**
     * constructLayout
     * @param {React.ReactElement[]} fields
     * @return {React.ReactElement}
     */
    const constructLayout=(fields:React.ReactElement[]):React.ReactElement => (
        <Grid container rowSpacing={1} columnSpacing={{xs: 2, sm: 2, md: 2}}>
            {fields.map((item:React.ReactElement) => <Grid item xs={12} key={item.key}>{item}</Grid>)}
        </Grid>
    );

    /**
     * onSubmit
     * @param {any} data
     * @return {Promise<void>}
     */
    const onSubmit=async (data:any):Promise<void> => {
        const res:any=await UserService.signIn(data);
        if (res.error) {
            // display snack message
            dispatch({
                type: "@@CONF/NOTIFIER_ENQUEUE",
                notification: {
                    message: "Wrong Credentials!",
                    options: {variant: "error", action: "DISMISS", persist: false},
                },
            });
        }
    };

    return (
        <Grid sx={isMD?{p: 3}:undefined} container spacing={0} direction="row" justifyContent="center" alignItems="center">
            <Grid item xs={12} sm={6} md={5} lg={3} xl={3}>
                {form.withForm(constructLayout(form.fields), onSubmit)}
            </Grid>
        </Grid>
    );
}

export default SignIn;
