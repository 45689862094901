import React from "react";
import {Line, LineChart, ResponsiveContainer, ReferenceLine, Tooltip, YAxis, Brush} from "recharts";
import {grey} from "@mui/material/colors";
import {
    Paper,
    Skeleton,
    Typography,
} from "@mui/material/";
import moment from "moment";
import {toStringDate} from "../../handler/firebase";
import Box from "../generics/Box";

export interface RecordType{
    key: string
    value:any
}

interface WeightTimelineChartProps{
    data:RecordType[]|undefined
    avgWeight:number
    isMD:boolean
    isSM:boolean
}

/**
 * CustomTooltip
 * @param {any} param
 * @return {React.ReactElement|null}
 */
function CustomTooltip(tooltipProps:any):React.ReactElement|null {
    if (tooltipProps.active && tooltipProps.payload && tooltipProps.payload.length===1) {
        return (
            <Paper sx={{padding: "4px"}} elevation={1}>
                <Typography variant="subtitle2">{`${toStringDate(moment(tooltipProps.payload[0].payload.key))}: ${tooltipProps.payload[0].value} lbs`}</Typography>
            </Paper>
        );
    }
    return null;
}

/**
 * WeightTimelineChart
 * @return {React.ReactElement}
 */
function WeightTimelineChart(props:WeightTimelineChartProps):React.ReactElement {
    if (!props.data || !Array.isArray(props.data)) return <Skeleton width="100%" height={280} />;
    return (
        <Box sx={props.isMD?{marginBottom: "28px"}:undefined}>
            <ResponsiveContainer width="100%" height={280}>
                <LineChart data={props.data}>
                    <ReferenceLine y={props.avgWeight} label={`${props.avgWeight} lbs`} stroke="green" />
                    <ReferenceLine y={210} label="210 lbs" stroke="red" strokeDasharray="3 3" />
                    <ReferenceLine y={140} label="140 lbs" stroke="red" strokeDasharray="3 3" />
                    <Tooltip wrapperStyle={{backgroundColor: grey[200], border: "none"}} content={<CustomTooltip />} />
                    <YAxis hide domain={[135, 211]} />
                    <Line
                        dot={false}
                        type="monotone"
                        dataKey="value"
                        stroke="#8884d8"
                        strokeWidth={2}
                    />
                    <Brush height={20} />
                </LineChart>
            </ResponsiveContainer>
        </Box>
    );
}

export default WeightTimelineChart;
