import React from "react";
import ReactDOM from "react-dom/client";
import {Provider} from "react-redux";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import {store} from "./config";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement,
);
root.render(
    <Provider store={store}>
        <App />
    </Provider>,
);

reportWebVitals();
