import {combineReducers, Action, Reducer} from "redux";
import {connectRouter, RouterState} from "connected-react-router";
import {all, fork} from "redux-saga/effects";
import {History} from "history";
import {ConfActionTypes, ConfState} from "./types/confType";
import {confReducer} from "./reducers/confReducer";
import confSaga from "./sagas/confSaga";

interface AppState {
    conf:ConfState
    router:RouterState
}

/**
 * appReducer
 * @param {History} history
 * @return {Reducer<AppState>}
 */
const appReducer=(history:History):Reducer<AppState> => combineReducers({
    conf: confReducer,
    router: connectRouter(history),
});

/**
 * rootReducer
 * @param {History} history
 * @return {Reducer<AppState>}
 */
export const rootReducer=(history:History):Reducer<AppState> => (state:any, action:Action) => {
    switch (action.type) {
        case ConfActionTypes.CONF_SIGNOUT: {
            localStorage.clear();
            return {
                ...state,
                conf: {...state.conf, user: null},
            };
        }
        default: {
            return appReducer(history)(state, action);
        }
    }
};

/**
 * rootSaga
 * @return {Generator}
 */
export function* rootSaga():Generator {
    yield all([
        fork(confSaga),
    ]);
}
