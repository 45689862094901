import React, {Suspense, useRef} from "react";
import * as THREE from "three";
import {Canvas, useFrame} from "@react-three/fiber";
import {OrbitControls, useGLTF, Environment, ContactShadows, useProgress} from "@react-three/drei";
import CanvasLoader from "../generics/CanvasLoader";
import Box from "../generics/Box";

/**
 * Model
 * @return {React.ReactElement}
 */
function Model(props:any):React.ReactElement {
    const phone = useGLTF("./iphone_14_pro/scene.gltf");
    const group:any= useRef();
    useFrame((state:any) => {
        const t = state.clock.getElapsedTime();
        ["x", "y", "z"].forEach((axis:string) => {
            group.current.rotation[axis]= THREE.MathUtils.lerp(group.current.rotation[axis], Math.cos(t/2)/5, 0.5);
        });
    });
    return (
        <group ref={group} {...props}>
            <mesh>
                {/* eslint-disable react/no-unknown-property */}
                <hemisphereLight intensity={0.15} groundColor="black" />
                <pointLight intensity={1} />
                <primitive
                    object={phone.scene}
                    scale={7.0}
                    position={[2.5, 0, -4.5]}
                    rotation={[0, -1.0, 0]}
                />
            </mesh>
        </group>
    );
}

/**
 * IPhone
 * @return {React.ReactElement}
 */
function IPhone():React.ReactElement {
    const {errors} = useProgress();

    if (errors.length!==0) {
        console.log(errors);
        return <div />;
    }

    return (
        <Box sx={{height: "450px"}}>
            <Canvas
                shadows
                dpr={[1, 2]}
                camera={{position: [0, 0, -15], fov: 55}}
            >
                <pointLight position={[60, 60, 60]} intensity={1.0} />
                <Suspense fallback={<CanvasLoader />}>
                    <Model />
                    <Environment path="/hdri/" files="potsdamer_platz_1k.hdr" />
                </Suspense>
                <ContactShadows position={[0, 0, -5]} scale={20} blur={2} far={4.5} />
                <OrbitControls
                    enablePan={false}
                    enableZoom={false}
                />
            </Canvas>
        </Box>
    );
}

export default IPhone;
