import React, {Dispatch, useCallback, useEffect, useState} from "react";
import {
    Grid,
    Typography,
    Chip,
} from "@mui/material";
import {red, deepPurple, blue} from "@mui/material/colors";
import {Location, useLocation} from "react-router-dom";
import {useDispatch} from "react-redux";
import {UnknownAction} from "redux";
import Box from "../components/generics/Box";
import PieChart, {RecordType} from "../components/layout/PieChart";
import GridData from "../components/generics/Grid";
import {BaseService} from "../services/firebase";
import {FORM_SCHEMA as MEAL_FORM_SCHEMA} from "../components/forms/Meal";
import {COLLECTIONS} from "../config";
import {MEAL_GRID_COL_DEFS} from "./Nutrition";

const MACROS_COLORS={
    protein: blue.A400,
    carbohydrates: deepPurple.A400,
    fat: red.A400,
};

interface FormatMeals{
    macros:RecordType[]
    totals:RecordType[]
    breakdown: {rows: any[], columns: any}
}

/**
 * NutritionDetail
 * @return {React.ReactElement}
 */
function NutritionDetail():React.ReactElement {
    const {state}:Location=useLocation();
    const dispatch:Dispatch<UnknownAction>=useDispatch();
    const [meals, setMeals]:any=useState(null);

    const isMeals=Array.isArray(state?.meals) && state?.meals.length!==0;

    const getDocs=useCallback(async () => {
        const GET_CALLS_PROPS={collection: COLLECTIONS.MEAL, set: setMeals, formSchema: MEAL_FORM_SCHEMA, gridColDef: MEAL_GRID_COL_DEFS, in: state.meals.map((m:any) => m.key)};
        const res=await BaseService.list(GET_CALLS_PROPS);
        if (res.error) {
            // display snack message
            dispatch({
                type: "@@CONF/NOTIFIER_ENQUEUE",
                notification: {
                    message: res.error.message,
                    options: {variant: "error", action: "DISMISS", persist: false},
                },
            });
        }
    }, [dispatch, state.meals]);

    useEffect(() => {
        if (isMeals) getDocs();
    }, [getDocs, isMeals]);

    /**
     * formatMeals
     * @param {any} data
     * @return {FormatMeals|undefined}
     */
    const formatMeals=(data:any):FormatMeals|undefined => {
        if (!data) return undefined;
        const accumulator:any={calories: 0, sodium: 0, potassium: 0, protein: 0, carbohydrates: 0, fat: 0};
        const macros:RecordType[]=[];
        const totals:RecordType[]=[];
        const breakdown:any={rows: [], columns: [...meals.columns.map((m:any) => ({...m}))]};
        // breakdown - push qty on index 3
        breakdown.columns.splice(3, 0, {field: "qty", headerName: "Qty", width: "80"});

        // iterate over meals
        data?.rows?.forEach((item:any) => {
            // resolve meal incoming from diet
            const meal=state.meals.find((m:any) => m.key===item.name);
            // init breakdown row AND add qty
            const breakdownRow:any={qty: meal.value.qty};
            // accumulate values along with Qtys
            Object.keys(item).forEach((k:string) => {
                // resolve breakdown row by with qty
                if (["measurement", "name", "id"].includes(k)) {
                    breakdownRow[k]=item[k];
                    return;
                }
                breakdownRow[k]=Math.round(item[k]*Number(meal.value.qty)*10)/10;

                // accumlate
                if (k in accumulator) accumulator[k]+=item[k]*Number(meal.value.qty);
            });
            // breakdown push row
            breakdown.rows.push(breakdownRow);
        });

        // round values
        Object.keys(accumulator).slice(0, 3).forEach((key:string) => { totals.push({key, value: Math.round(accumulator[key]*10)/10}); });
        Object.keys(accumulator).slice(3, 6).forEach((key:string) => { macros.push({key, value: Math.round(accumulator[key]*10)/10}); });

        return {macros, totals, breakdown};
    };

    const data:FormatMeals|undefined=formatMeals(meals);
    const empty=<Typography sx={{marginBottom: "8px"}} color="inherit" variant="body1">None</Typography>;

    return (
        <Box>
            <Grid
                sx={{
                    padding: {xs: "24px", md: "0px"},
                    paddingLeft: {xs: "24px", md: "160px"},
                    paddingRight: {xs: "24px", md: "160px"},
                }}
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={3}
            >
                {/* name + type */}
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography color="inherit" variant="h4">
                        {state?.name}
                        <Chip sx={{marginLeft: "8px"}} label={state?.type} size="small" variant="outlined" />
                    </Typography>
                </Grid>
                {/* supplements + totals */}
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Typography sx={{marginBottom: "8px"}} color="inherit" variant="h6">Supplements</Typography>
                    {Array.isArray(state?.supplements) && state?.supplements.length!==0
                        ?state?.supplements?.map((s:any) => <Chip key={s} sx={{marginRight: "8px", marginBottom: "8px"}} label={s} size="small" variant="outlined" />)
                        :empty}
                    <Typography sx={{marginBottom: "8px"}} color="inherit" variant="h6">Totals</Typography>
                    {isMeals
                        ?data?.totals?.map((rec:RecordType) => <Chip key={rec.key} sx={{marginRight: "8px", marginBottom: "8px"}} label={`${rec.key[0].toUpperCase()}${rec.key.slice(1).toLowerCase()}: ${rec.value}`} size="small" variant="outlined" />)
                        :empty}
                </Grid>
                {/* Macronutrients */}
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Typography sx={{marginBottom: "8px"}} color="inherit" variant="h6">Macronutrients</Typography>
                    <PieChart colors={MACROS_COLORS} data={data?.macros} />
                </Grid>
                {/* Grid Instance */}
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {isMeals
                        ?(data?.breakdown
                            && (
                                <GridData
                                    initialState={{
                                        columns: {
                                            columnVisibilityModel: {
                                                id: false,
                                            },
                                        },
                                    }}
                                    label="Breakdown"
                                    rows={data.breakdown.rows}
                                    height={500}
                                    columns={data.breakdown.columns}
                                    paginationModel={{page: 0, pageSize: 25}}
                                    pageSizeOptions={[10, 25, 50, 100]}
                                />
                            )
                        )
                        :(
                            <Box>
                                <Typography sx={{marginBottom: "8px"}} color="inherit" variant="h6">Breakdown</Typography>
                                {empty}
                            </Box>
                        )}
                </Grid>
            </Grid>
        </Box>
    );
}

export default NutritionDetail;
