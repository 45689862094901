import React from "react";
import {SnackbarProvider} from "notistack";
import {BrowserRouter as Router} from "react-router-dom";
import Routes from "./views/Routes";
import {SNACK_PROVIDER_CONFIG} from "./config";
import "./App.css";

/**
 * App
 * @return {React.ReactElement}
 */
function App():React.ReactElement {
    return (
        <SnackbarProvider {...SNACK_PROVIDER_CONFIG}>
            <Router><Routes /></Router>
        </SnackbarProvider>
    );
}

export default App;
