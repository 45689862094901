import React from "react";
import {
    Theme,
    ThemeProvider,
    createTheme,
    styled,
} from "@mui/material/styles";
import {
    CircularProgress,
} from "@mui/material";
import {grey, red} from "@mui/material/colors";
import {Routes as RoutesSwitch, Route, useLocation} from "react-router-dom";

import {APP_PATHS} from "../config";
// components
import Box from "../components/generics/Box";
import MenuBar from "../components/layout/MenuBar";
import SignIn from "../components/forms/SignIn";
import useNotifier from "../components/generics/useNotifier";
import PrivateRoute from "../components/generics/PrivateRoute";
import useAuth, {AuthProps} from "../components/generics/useAuth";

// views
import Home from "./Home";
// import Profile from "./Profile";
import Nutrition from "./Nutrition";
import Workout from "./Workout";
import Routine from "./Routine";
import About from "./About";
import Http404 from "./Http404";
import WorkoutDetail from "./WorkoutDetail";
import NutritionDetail from "./NutritionDetail";

const Main=styled("main", {shouldForwardProp: (prop:any) => prop!=="home"})<{
    home?:boolean;
    }>(({theme, home}) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        [theme.breakpoints.down("md")]: {
            padding: theme.spacing(0),
        },
        marginLeft: 0,
        ...(home && {
            backgroundColor: grey[700],
            height: "100%",
        }),
    }));

const AppBarSpacer = styled("div")(({theme}) => ({
    ...theme.mixins.toolbar,
    [theme.breakpoints.down("md")]: {
        minHeight: theme.spacing(6),
    },
}));

const theme:Theme=createTheme({
    palette: {
        primary: {
            main: grey[900],
        },
        secondary: {
            main: red.A700,
        },
    },
    typography: {
        fontFamily: "Poppins, sans-serif",
    },
});

/**
 * Routes
 * @return {React.ReactElement}
 */
function Routes():React.ReactElement {
    useNotifier();
    const auth:AuthProps=useAuth();
    const location=useLocation();
    const isHome=location.pathname===APP_PATHS.HOME;

    /**
     * constructMenuBar
     * @return {React.ReactElement}
     */
    const constructMenuBar=():React.ReactElement => (
        <>
            {!isHome && <AppBarSpacer />}
            <MenuBar auth={auth} />
        </>
    );

    /**
     * constructRoutes
     * @return {React.ReactElement}
     */
    const constructRoutes=():React.ReactElement => (
        <RoutesSwitch>
            <Route path={APP_PATHS.HOME} element={<Home />} />
            <Route path={APP_PATHS.SIGNIN} element={<SignIn auth={auth} />} />
            <Route path={APP_PATHS.ABOUT} element={<About />} />
            {/* <Route path={APP_PATHS.PROFILE} element={<PrivateRoute component={<Profile />} auth={auth} />} /> */}
            <Route path={APP_PATHS.WORKOUT} element={<PrivateRoute component={<Workout />} auth={auth} />} />
            <Route path={APP_PATHS.NUTRITION} element={<PrivateRoute component={<Nutrition />} auth={auth} />} />
            <Route path={APP_PATHS.ROUTINE} element={<PrivateRoute component={<Routine />} auth={auth} />} />
            <Route path={APP_PATHS.WORKOUT_DETAIL} element={<PrivateRoute component={<WorkoutDetail />} auth={auth} />} />
            <Route path={APP_PATHS.NUTRITION_DETAIL} element={<PrivateRoute component={<NutritionDetail />} auth={auth} />} />
            <Route path="*" element={<Http404 />} />
        </RoutesSwitch>
    );

    return (
        <ThemeProvider theme={theme}>
            {constructMenuBar()}
            <Main home={isHome}>
                {auth.isLoading?<Box sx={{display: "flex", alignItems: "center", justifyContent: "center", minHeight: "75vh"}}><CircularProgress /></Box>:constructRoutes()}
            </Main>
        </ThemeProvider>
    );
}

export default Routes;
