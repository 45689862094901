import React, {Dispatch} from "react";
import {Grid, Typography, Card, CardContent, CardActionArea, Chip, CardMedia, Link} from "@mui/material";
import {Tilt} from "react-tilt";
import {motion} from "framer-motion";
import {grey} from "@mui/material/colors";
import {useDispatch} from "react-redux";
import {UnknownAction} from "redux";
import emailjs from "@emailjs/browser";
import Box from "../components/generics/Box";
import {TIERS, OVERVIEW_CONTENT, EXPERIENCE_CONTENT, TAGS, EDUCATION_CONTENT, DEGREES, SOCIAL_MEDIA} from "../config";
import Contact from "../components/forms/Contact";

export interface TierCardProps{
    label:string
    icon:React.ReactElement
}

export interface DegreeCardProps{
    label:string
    img:string
    url:string
}

export interface TagProps{
    tag:string
    url:string
}

interface GridBlockProps{
    header:string
    body:string
    children: React.ReactElement
    childrenStyle?:any
}

interface MotionProps{
    component: React.ReactElement
}

/**
 * Motion
 * @param {MotionProps} motionProps
 * @return {React.ReactElement}
 */
function Motion(motionProps:MotionProps):React.ReactElement {
    return (
        <Tilt options={{max: 45, scale: 1, speed: 450}}>
            <motion.div
                whileHover={{scale: 1.2}}
                whileTap={{scale: 1.2}}
                initial={{opacity: 0, scale: 0.5}}
                animate={{opacity: 1, scale: 1}}
                transition={{duration: 0.8, ease: [0, 0.71, 0.2, 1.01]}}
            >
                {motionProps.component}
            </motion.div>
        </Tilt>
    );
}
/**
 * GridBlock
 * @param {GridBlockProps} gridBlockProps
 * @return {React.ReactElement}
 */
function GridBlock(gridBlockProps:GridBlockProps):React.ReactElement {
    return (
        <>
            {/* header */}
            <Grid item xs={12}><Typography variant="h3" sx={{fontWeight: 600}}>{gridBlockProps.header}</Typography></Grid>
            {/* body */}
            <Grid item lg={10} xs={12}><Typography variant="body1">{gridBlockProps.body}</Typography></Grid>
            {/* children */}
            <Grid
                item
                xs={12}
                sx={{
                    paddingLeft: {sx: 24, md: 0, lg: 12, xl: 30},
                    paddingRight: {sx: 24, md: 0, lg: 12, xl: 30},
                    marginTop: 6,
                    marginBottom: 4,
                    ...gridBlockProps.childrenStyle,
                }}
            >
                {gridBlockProps.children}
            </Grid>
        </>
    );
}

/**
 * Tag
 * @param {TagProps} tagProps
 * @return {React.ReactElement}
 */
function Tag(tagProps:TagProps):React.ReactElement {
    return (<Motion component={<Chip sx={{fontWeight: "bold", borderRadius: "8px"}} label={tagProps.tag} variant="outlined" onClick={() => null} component="a" href={tagProps.url} />} />);
}

/**
 * TierCard
 * @param {TierCardProps} tierCardProps
 * @return {React.ReactElement}
 */
export function TierCard(tierCardProps:TierCardProps):React.ReactElement {
    return (
        <Motion
            component={(
                <Card elevation={4}>
                    <CardActionArea>
                        <CardContent sx={{paddingTop: {xs: "6px", md: "16px"}, paddingBottom: {xs: "6px", md: "16px"}}}>
                            <Grid container direction="column" justifyContent="center" alignItems="center">
                                {/* icon */}
                                <Grid item xs={12}>{tierCardProps.icon}</Grid>
                                {/* label */}
                                <Grid item xs={12}><Typography variant="h6" sx={{fontWeight: 600, fontSize: {xs: "0.75rem", sm: "1.25rem", md: "1.25rem"}}}>{tierCardProps.label}</Typography></Grid>
                            </Grid>
                        </CardContent>
                    </CardActionArea>
                </Card>
            )}
        />
    );
}

/**
 * DegreeCard
 * @param {DegreeCardProps} degreeCardProps
 * @return {React.ReactElement}
 */
function DegreeCard(degreeCardProps:DegreeCardProps):React.ReactElement {
    return (
        <Motion
            component={(
                <Card elevation={4} sx={{maxWidth: 400}}>
                    {/* img */}
                    <CardMedia component="img" sx={{maxWidth: 400}} src={degreeCardProps.img} alt={degreeCardProps.label} />
                    {/* label */}
                    <CardActionArea component="a" href={degreeCardProps.url}>
                        <CardContent sx={{height: 100, textAlign: "center", backgroundColor: grey[300]}}>
                            <Typography variant="h6">{degreeCardProps.label}</Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            )}
        />
    );
}

/**
 * About
 * @return {React.ReactElement}
 */
function About():React.ReactElement {
    const dispatch:Dispatch<UnknownAction>=useDispatch();
    /**
     * onContact
     * @param {any} onReset
     */
    const onContact= (onReset:any) => async (payload:any):Promise<void> => {
        await emailjs.send(
            process.env.REACT_APP_EMAILJS_SERVICE_ID as string,
            process.env.REACT_APP_EMAILJS_TEMPLATE_ID as string,
            payload,
            process.env.REACT_APP_EMAILJS_PUBLIC_KEY as string,
        ).then(
            (response:any) => {
                onReset();
                dispatch({
                    type: "@@CONF/NOTIFIER_ENQUEUE",
                    notification: {
                        message: `Thank you for contacting me ${payload.name}! I will return to you very soon.`,
                        options: {variant: "success", action: "DISMISS", persist: false},
                    },
                });
            },
            (err:any) => {
                dispatch({
                    type: "@@CONF/NOTIFIER_ENQUEUE",
                    notification: {
                        message: `There was a problem with sending ${payload.name}, Sorry!`,
                        options: {variant: "error", action: "DISMISS", persist: false},
                    },
                });
            },
        );
    };

    // blocks
    const blocks:GridBlockProps[]=[
        {
            header: "Overview.",
            body: OVERVIEW_CONTENT,
            children: (
                <Grid container columnSpacing={{xs: 1, md: 4, lg: 12}} direction="row" justifyContent="space-evenly" alignItems="stretch">
                    {TIERS.map((tier:TierCardProps) => (<Grid item xs={4} key={tier.label}><TierCard {...tier} /></Grid>))}
                </Grid>
            ),
        },
        {
            header: "Experience.",
            body: EXPERIENCE_CONTENT,
            children: (
                <Grid container spacing={1} direction="row" justifyContent="center" alignItems="center">
                    {TAGS.map((item:any) => <Grid item key={item.tag}><Tag {...item} /></Grid>)}
                </Grid>
            ),
        },
        {
            header: "Education.",
            body: EDUCATION_CONTENT,
            children: (
                <Grid container spacing={{xs: 1, md: 1, lg: 4, xl: 12}} direction="row" justifyContent="center" alignItems="center">
                    {DEGREES.map((degree:DegreeCardProps) => (<Grid item key={degree.label}><DegreeCard {...degree} /></Grid>))}
                </Grid>
            ),
            childrenStyle: {paddingLeft: "0px", paddingRight: "0px"},
        },
        {
            header: "Contact.",
            body: "Get in touch.",
            children: (<Contact onSubmit={onContact} />),
            childrenStyle: {paddingLeft: 0, paddingRight: 0},
        },
    ];

    return (
        <Box>
            <Grid
                sx={{
                    padding: {xs: "24px", md: "0px"},
                    paddingLeft: {xs: "24px", md: "160px"},
                    paddingRight: {xs: "24px", md: "160px"},
                }}
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
            >
                {/* blocks */}
                {blocks.map((block:GridBlockProps) => (
                    <GridBlock key={block.header} header={block.header} body={block.body} childrenStyle={block.childrenStyle}>{block.children}</GridBlock>
                ))}
            </Grid>
            {/* social media */}
            <Grid sx={{marginTop: "15px"}} spacing={2} container direction="row" justifyContent="center" alignItems="flex-start">
                {SOCIAL_MEDIA.map((item:any) => <Grid item key={item.url}><Link href={item.url}>{item.icon}</Link></Grid>)}
            </Grid>
        </Box>
    );
}

export default About;
