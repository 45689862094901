import React from "react";
import {SpeedDial as SpeedDialBase} from "@mui/material/";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import Close from "@mui/icons-material/Close";
import Box from "./Box";

interface ActionProps{
    icon: React.ReactElement
    name: string
    onClick: (args:React.MouseEvent<HTMLElement>) => void
}

interface SpeedDialIProps{
    actions: ActionProps[]
    icon: React.ReactElement
    ariaLabel: string
}

/**
 * SpeedDial
 * @return {React.ReactElement}
 */
function SpeedDial(props:SpeedDialIProps):React.ReactElement {
    return (
        <Box>
            <SpeedDialBase
                ariaLabel={props.ariaLabel}
                sx={{position: "absolute", bottom: 64, right: 24}}
                icon={<SpeedDialIcon icon={props.icon} openIcon={<Close />} />}
            >
                {props.actions.map((action:ActionProps) => (
                    <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        FabProps={{disabled: action.onClick===null}}
                        tooltipTitle={action.name}
                        onClick={action.onClick}
                    />
                ))}
            </SpeedDialBase>
        </Box>
    );
}

export default SpeedDial;
