import {Reducer, UnknownAction} from "redux";
import {ConfState, ConfActionTypes, Notification, DialogId} from "../types/confType";

/**
 * initconfState
 * @return {ConfState}
 */
const initconfState=():ConfState => ({
    notifications: [] as Notification[],
    dialogId: "NONE",
    currentRow: null,
});

const initialState:ConfState=initconfState();

/**
 * confReducer
 * @param {ConfState} state
 * @param {Action} action
 * @returns
 */
const confReducer:Reducer<ConfState>=(state=initialState, action:UnknownAction):ConfState => {
    switch (action.type) {
        // -------------------------------------------LAYOUT
        case ConfActionTypes.CONF_SET_DIALOG_ID: {
            return {
                ...state,
                dialogId: action.dialogId,
            } as ConfState;
        }

        case ConfActionTypes.CONF_SET_CURRENT_ROW: {
            return {
                ...state,
                currentRow: action.currentRow,
                ...(action.dialogId as DialogId && {dialogId: action.dialogId}),
            } as ConfState;
        }

        // -------------------------------------------NOTIFIER cases
        case ConfActionTypes.CONF_NOTIFIER_ENQUEUE: {
            const note:Notification=action.notification as Notification;
            return {
                ...state,
                notifications: [
                    ...state.notifications,
                    {
                        ...note,
                        key: note.key?note.key:(new Date().getTime()+Math.random()),
                    },
                ],
            };
        }

        case ConfActionTypes.CONF_NOTIFIER_CLOSE: {
            return {
                ...state,
                notifications: state.notifications.map((notification:Notification) => (
                    (notification.key===action.key)?{...notification, dismissed: true}:{...notification}
                )),
            };
        }

        case ConfActionTypes.CONF_NOTIFIER_REMOVE: {
            return {
                ...state,
                notifications: state.notifications.filter((notification:Notification) => notification.key!==action.key),
            };
        }
        default: return state;
    }
};

export {confReducer};
