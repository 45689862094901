import React, {useState, useCallback} from "react";
import {PieChart as PieChartBase, Pie, Sector, ResponsiveContainer, Cell} from "recharts";
import {
    Skeleton,
    Grid,
    Typography,
} from "@mui/material/";
import Box from "../generics/Box";
import "./PieChart.css";

export interface RecordType{
    key: string
    value:any
}

interface PieChartProps{
    data:RecordType[]|undefined
    colors?:any
    isMD?:boolean
    isSM?:boolean
}

/**
 * PieChart
 * @return {React.ReactElement}
 */
function PieChart(props:PieChartProps):React.ReactElement {
    const [activeIndex, setActiveIndex]=useState(0);

    /**
     * renderActiveShape
     * @param {any} activeShapeProps
     * @return {React.ReactElement}
     */
    const renderActiveShape = (activeShapeProps:any):React.ReactElement => {
        const RADIAN=Math.PI/180;
        const {
            cx,
            cy,
            midAngle,
            innerRadius,
            outerRadius,
            startAngle,
            endAngle,
            fill,
            payload,
            percent,
            value,
        } = activeShapeProps;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const sx = cx + (outerRadius + 10) * cos;
        const sy = cy + (outerRadius + 10) * sin;
        const mx = cx + (outerRadius + 10) * cos;
        const my = cy + (outerRadius + 30) * sin;
        const ex = (cos >= 0?(mx-15):(mx+15)) + (cos >= 0 ? 1 : -1) * 22;
        const ey = my;
        const textAnchor = cos >= 0 ? "start" : "end";
        return (
            <g>
                <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>{`${payload.key[0].toUpperCase()}${payload.key.slice(1).toLowerCase()}`}</text>
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}
                />
                <Sector
                    cx={cx}
                    cy={cy}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    innerRadius={outerRadius + 6}
                    outerRadius={outerRadius + 10}
                    fill={fill}
                />
                <path
                    d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
                    stroke={fill}
                    fill="none"
                />
                <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                <text
                    x={ex + (cos >= 0 ? 1 : -1) * 12}
                    y={ey}
                    textAnchor={textAnchor}
                    fill="#333"
                >
                    {`${Math.round(value*10)/10}`}
                </text>
                <text
                    x={ex + (cos >= 0 ? 1 : -1) * 12}
                    y={ey}
                    dy={18}
                    textAnchor={textAnchor}
                    fill="#999"
                >
                    {`${(percent * 100).toFixed(0)}%`}
                </text>
            </g>
        );
    };

    const onPieEnter = useCallback((_:any, index:number) => setActiveIndex(index), [setActiveIndex]);

    if (!props.data || !Array.isArray(props.data)) return <Skeleton width="100%" height={280} />;
    if (props.data.length===0) {
        return (
            <Grid sx={{height: "280px"}} container direction="row" justifyContent="center" alignItems="center">
                <Grid item><Typography>No Data!</Typography></Grid>
            </Grid>
        );
    }
    return (
        <Box>
            <ResponsiveContainer width="100%" height={280}>
                <PieChartBase>
                    <Pie
                        activeIndex={activeIndex}
                        activeShape={renderActiveShape}
                        data={props.data}
                        innerRadius={65}
                        outerRadius={85}
                        fill="#8884d8"
                        dataKey="value"
                        onMouseEnter={onPieEnter}
                    >
                        {props.data && props.colors && (
                            props.data?.map((record:RecordType) => (<Cell key={`cell-${record.key}`} fill={props.colors[record.key]} />))
                        )}
                    </Pie>
                </PieChartBase>
            </ResponsiveContainer>
        </Box>
    );
}

PieChart.defaultProps={
    isMD: undefined,
    isSM: undefined,
    colors: undefined,
};

export default PieChart;
