import {QuerySnapshot, DocumentData, Timestamp} from "firebase/firestore";
import {GridColDef} from "@mui/x-data-grid";
import moment, {Moment} from "moment";
import {FormSchema} from "../../store/types/confType";

const COLUMNS_DEFS_BASE:any=[
    {field: "id", headerName: "ID", status: false},
];

/**
 * formatList
 * @param {any} querySnapshot
 * @return {any}
 */
export const formatList=(querySnapshot:QuerySnapshot, schema:FormSchema[], gridColDef?:any):any => {
    const columns=[...COLUMNS_DEFS_BASE];
    const rows:any[]=[];
    if (!querySnapshot) return {rows, columns};
    // columns definitions
    schema.forEach((item:FormSchema) => {
        let columnDef:GridColDef={field: item.id, headerName: item.label};
        if (gridColDef && gridColDef[item.id]) columnDef={...columnDef, ...gridColDef[item.id]};
        columns.push(columnDef);
    });

    // rows
    querySnapshot.forEach((doc:DocumentData) => {
        rows.push({id: doc.id, ...doc.data()});
    });

    return {rows, columns};
};

/**
 * isDateFalls
 * @param {Timestamp} timeStamp
 * @param {number} days
 * @return {boolean|null}
 */
export const isDateFalls=(timeStamp:Timestamp, days=2):boolean|null => {
    if (!(timeStamp instanceof Timestamp)) return null;
    const currentMDate=moment();
    const mDate=moment(timeStamp.toDate());
    const dateDiff=Math.abs(currentMDate.diff(mDate, "days"));
    return dateDiff<=days;
};

/**
 * toStringDate
 * @param {Moment} date
 * @param {string} format
 * @return {string}
 */
export const toStringDate=(date:Moment, format="MM-DD-YYYY"):string => moment(date.toDate()).format(format);

/**
 * resolveFileName
 * @param {Date} date
 * @param {string} fileName
 * @return {string}
 */
export const resolveFileName=(date:Date, fileName:string):string => {
    if (!(date instanceof Date) || !fileName) return "";
    return `${toStringDate(moment(date), "YYYY-MM-DD")}.${fileName?.split(".")[1]}`;
};

/**
 * logFilterHandler
 * @param {any[]} data
 */
export const logFilterHandler=(data:any[]):any[]|null => {
    // escape if no data
    if (!data) return null;
    let dataSet=data.map((record:any) => ({...record}));
    // sort dataset
    dataSet=dataSet.sort((a:any, b:any) => b.name.toDate()-a.name.toDate());
    return dataSet;
};
