import createSagaMiddleware, {SagaMiddleware} from "redux-saga";
import {Store} from "redux";
import {configureStore} from "@reduxjs/toolkit";
import {createBrowserHistory, History} from "history";
import {rootReducer, rootSaga} from "../store";

const history:History=createBrowserHistory();
const sagaMiddleware:SagaMiddleware<any>=createSagaMiddleware();
const store:Store=configureStore({
    reducer: rootReducer(history),
    middleware: (gdm:any) => gdm({
        serializableCheck: false,
    }).concat(sagaMiddleware),
});
sagaMiddleware.run(rootSaga);

export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store;
