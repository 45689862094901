import React, {useMemo, useCallback} from "react";
import {Editor, BaseEditor, Transforms, createEditor, Descendant, Element as SlateElement} from "slate";
import {withHistory} from "slate-history";
import {Editable, withReact, useSlate, Slate} from "slate-react";
import isHotkey from "is-hotkey";
import {styled, Theme} from "@mui/material/styles";
import {
    Grid,
    ToggleButton,
    ToggleButtonGroup,
    Paper,
    Divider,
} from "@mui/material/";
import {
    // ArrowDropDown,
    // FormatColorFill,
    FormatUnderlined,
    FormatItalic,
    FormatBold,
    // FormatAlignJustify,
    FormatAlignRight,
    FormatAlignCenter,
    FormatAlignLeft,
    Code,
    LooksOne,
    LooksTwo,
    FormatQuote,
    FormatListBulleted,
    FormatListNumbered,
} from "@mui/icons-material/";

declare module "slate" {
    export interface BaseElement{
        [key: string]: unknown
        type: string
    }
}

interface RichTextProps{
    onChange: (value:Descendant[]) => void
    currentRow:any
    isMD:boolean
}

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({theme}) => ({
    "& .MuiToggleButtonGroup-grouped": {
        margin: theme.spacing(0.5),
        border: 0,
        "&.Mui-disabled": {
            border: 0,
        },
        "&:not(:first-of-type)": {
            borderRadius: theme.shape.borderRadius,
        },
        "&:first-of-type": {
            borderRadius: theme.shape.borderRadius,
        },
    },
}));

const LIST_TYPES=["numbered-list", "bulleted-list"];
const TEXT_ALIGN_TYPES=["left", "center", "right", "justify"];

const HOTKEYS:any={
    "mod+b": "bold",
    "mod+i": "italic",
    "mod+u": "underline",
    "mod+`": "code",
};

/**
 * toggleMark
 * @param {BaseEditor} editor
 * @param {string} format
 * @return {boolean}
 */
const isMarkActive=(editor:BaseEditor, format:string):boolean => {
    const marks:any= Editor.marks(editor);
    return marks?marks[format]===true:false;
};

/**
 * isBlockActive
 * @param {BaseEditor} editor
 * @param {string} format
 * @param {string} blockType
 * @return {boolean}
 */
const isBlockActive=(editor:BaseEditor, format:string, blockType="type"):boolean => {
    if (!editor.selection) return false;
    const [match] = Array.from(
        Editor.nodes(editor, {
            at: Editor.unhangRange(editor, editor.selection),
            match: (n:any) => (!Editor.isEditor(n) && SlateElement.isElement(n) && n[blockType]===format),
        }),
    );
    return !!match;
};

/**
 * toggleMark
 * @param {BaseEditor} editor
 * @param {string} format
 * @return {void}
 */
const toggleMark=(editor:BaseEditor, format:string):void => {
    if (isMarkActive(editor, format)) Editor.removeMark(editor, format);
    else Editor.addMark(editor, format, true);
};

/**
 * toggleBlock
 * @param {BaseEditor} editor
 * @param {string} format
 * @return {void}
 */
const toggleBlock=(editor:BaseEditor, format:string):void => {
    const isActive:boolean=isBlockActive(editor, format, TEXT_ALIGN_TYPES.includes(format) ? "align":"type");
    const isList:boolean=LIST_TYPES.includes(format);
    Transforms.unwrapNodes(editor, {
        /* eslint-disable no-nested-ternary */
        match: (n:any) => (!Editor.isEditor(n) && SlateElement.isElement(n) && LIST_TYPES.includes(n.type) && !TEXT_ALIGN_TYPES.includes(format)),
        split: true,
    });
    let newProperties:Partial<SlateElement>;
    if (TEXT_ALIGN_TYPES.includes(format)) newProperties = {align: isActive?undefined:format};
    else newProperties = {type: isActive ? "paragraph" : isList ? "list-item" : format};

    Transforms.setNodes<SlateElement>(editor, newProperties);

    if (!isActive && isList) Transforms.wrapNodes(editor, ({type: format, children: []}));
};

/**
 * Element
 * @param {any} props
 * @return {React.ReactElement}
 */
function Element({attributes, children, element}:any):React.ReactElement {
    const style = {textAlign: element.align};
    switch (element.type) {
        case "block-quote":
            return (
                <blockquote
                    style={{
                        ...style,
                        color: "#aaa",
                        fontStyle: "italic",
                        paddingLeft: "10px",
                        borderLeft: "2px solid #ddd",
                    }}
                    {...attributes}
                >
                    {children}
                </blockquote>
            );
        case "bulleted-list":
            return (<ul style={style} {...attributes}>{children}</ul>);
        case "heading-one":
            return (<h1 style={style} {...attributes}>{children}</h1>);
        case "heading-two":
            return (<h2 style={style} {...attributes}>{children}</h2>);
        case "list-item":
            return (<li style={style} {...attributes}>{children}</li>);
        case "numbered-list":
            return (<ol style={style} {...attributes}>{children}</ol>);
        default:
            return (<p style={style} {...attributes}>{children}</p>);
    }
}

/**
 * Leaf
 * @param {any} props
 * @return {React.ReactElement}
 */
function Leaf({attributes, children, leaf}:any):React.ReactElement {
    /* eslint-disable no-param-reassign */
    if (leaf.bold) children = <strong>{children}</strong>;
    if (leaf.code) children = <code style={{backgroundColor: "#eee", padding: "3px"}}>{children}</code>;
    if (leaf.italic) children = <em>{children}</em>;
    if (leaf.underline) children = <u>{children}</u>;
    return <span {...attributes}>{children}</span>;
}

/**
 * Toolbar
 * @return {React.ReactElement}
 */
function Toolbar():React.ReactElement {
    const editor:BaseEditor= useSlate();

    /**
     * onAlignmentChange
     * @param {React.MouseEvent<HTMLElement>} args
     * @param {string} newAlignment
     */
    const onAlignmentChange=(args: React.MouseEvent<HTMLElement>, newValue: string):void => {
        toggleBlock(editor, Array.isArray(newValue)?newValue[0]:newValue);
    };

    /**
     * onFormatChange
     * @param {React.MouseEvent<HTMLElement>} args
     * @param {string} newAlignment
     */
    const onFormatChange=(args: React.MouseEvent<HTMLElement>, newValue: string):void => {
        toggleMark(editor, newValue);
    };

    return (
        <Grid container direction="row" justifyContent="center" alignItems="center">
            <Paper
                sx={{
                    display: "flex",
                    border: (theme:Theme) => `1px solid ${theme.palette.divider}`,
                    flexWrap: "wrap",
                }}
            >
                <StyledToggleButtonGroup size="small" exclusive onChange={onAlignmentChange}>
                    {[
                        {value: "left", icon: <FormatAlignLeft />},
                        {value: "center", icon: <FormatAlignCenter />},
                        {value: "right", icon: <FormatAlignRight />},
                        // {value: "justify", icon: <FormatAlignJustify />},
                    ].map((item:any) => (
                        <ToggleButton key={item.value} value={item.value} selected={isBlockActive(editor, item.value, "align")}>{item.icon}</ToggleButton>
                    ))}
                </StyledToggleButtonGroup>
                <Divider flexItem orientation="vertical" sx={{mx: 0.5, my: 1}} />
                <StyledToggleButtonGroup size="small" onChange={onFormatChange}>
                    {[
                        {value: "bold", icon: <FormatBold />},
                        {value: "italic", icon: <FormatItalic />},
                        {value: "underline", icon: <FormatUnderlined />},
                        {value: "code", icon: <Code />},
                    ].map((item:any) => (
                        <ToggleButton key={item.value} value={item.value} selected={isMarkActive(editor, item.value)}>{item.icon}</ToggleButton>
                    ))}
                </StyledToggleButtonGroup>
                <Divider flexItem orientation="vertical" sx={{mx: 0.5, my: 1}} />
                <StyledToggleButtonGroup size="small" onChange={onAlignmentChange}>
                    {[
                        {value: "heading-one", icon: <LooksOne />},
                        {value: "heading-two", icon: <LooksTwo />},
                        {value: "block-quote", icon: <FormatQuote />},
                        {value: "numbered-list", icon: <FormatListNumbered />},
                        {value: "bulleted-list", icon: <FormatListBulleted />},
                    ].map((item:any) => (
                        <ToggleButton key={item.value} value={item.value} selected={isBlockActive(editor, item.value)}>{item.icon}</ToggleButton>
                    ))}
                </StyledToggleButtonGroup>
            </Paper>
        </Grid>
    );
}

/**
 * RichText
 * @return {React.ReactElement}
 */
function RichText(richTextProps:RichTextProps):React.ReactElement {
    const renderElement=useCallback((props:any) => <Element {...props} />, []);
    const renderLeaf=useCallback((props:any) => <Leaf {...props} />, []);
    const editor=useMemo(() => withHistory(withReact(createEditor())), []);

    /**
     * onKeyDown
     * @param {React.KeyboardEvent} args
     */
    const onKeyDown=(args:React.KeyboardEvent):void => {
        Object.keys(HOTKEYS).forEach((key:any) => {
            if (isHotkey(key, args as any)) {
                args.preventDefault();
                toggleMark(editor, HOTKEYS[key]);
            }
        });
    };

    const initialValue=useMemo(
        () => {
            if (richTextProps.currentRow.comment) return JSON.parse(richTextProps.currentRow.comment);
            return [{type: "paragraph", children: [{text: ""}]}];
        },
        [richTextProps.currentRow.comment],
    );
    const h=richTextProps.isMD?`${window.innerHeight-250}px`:"300px";
    return (
        <Slate editor={editor} initialValue={initialValue} onChange={richTextProps.onChange}>
            <Toolbar />
            <Editable
                style={{height: h, maxHeight: h, overflow: "auto", outline: "none"}}
                renderElement={renderElement}
                renderLeaf={renderLeaf}
                spellCheck
                autoFocus
                onKeyDown={onKeyDown}
            />
        </Slate>
    );
}

export default RichText;
