import React, {useState, Dispatch, SetStateAction, useEffect} from "react";
import {Grid, Paper, CircularProgress, Link} from "@mui/material/";
import Joi from "joi";
import useForm, {IForm} from "../generics/useForm";
import {FormSchema, DialogId} from "../../store/types/confType";
import Dialog from "../generics/Dialog";
import {Util} from "../../services/firebase";
import {JOI_MIN_DATE, JOI_MAX_DATE, JOI_DATE_FORMAT} from "../../config";

const DEFAULT_VALUES:any={
    diet: ["cheat"],
    workout: ["rest"],
};

export const FORM_SCHEMA:FormSchema[]=[
    {
        id: "weight",
        type: "text",
        label: "Weight",
        joiOptions: Joi.number().min(140).max(210).allow("")
            .messages({
                "number.base": "Set a Number",
                "number.min": "Min 140",
                "number.max": "Max 210",
            }),
    },
    {
        id: "diet",
        type: "autocomplete",
        label: "Diet",
        autocompleteOptions: {
            options: [],
            multiple: false,
        },
        joiOptions: Joi.string().required()
            .messages({
                "string.empty": "Select",
                "string.base": "Select",
            }),
    },
    {
        id: "workout",
        type: "autocomplete",
        label: "Workout",
        autocompleteOptions: {
            options: [],
            multiple: false,
        },
        joiOptions: Joi.string().required()
            .messages({
                "string.empty": "Select",
                "string.base": "Select",
            }),
    },
    {
        id: "name", // NOTE: id is "name" instead of date or something else due that BaseService make use of name attribute as primary key
        type: "datepicker",
        label: "Date",
        joiOptions: Joi.date()
            .required()
            .min(JOI_MIN_DATE.toDate())
            .max(JOI_MAX_DATE.toDate())
            .messages({
                "any.required": "Required",
                "date.base": "Invalid Date",
                "date.min": `Min ${JOI_MIN_DATE.format(JOI_DATE_FORMAT).toString()}`,
                "date.max": `Max ${JOI_MAX_DATE.format(JOI_DATE_FORMAT).toString()}`,
            }),
    },
    {
        id: "imageURL",
        type: "file",
        label: "Image",
        autocompleteOptions: {
            options: [],
            multiple: false,
        },
        joiOptions: Joi.array().max(1).items()
            .messages({
                "array.min": "Upload a File",
                "array.max": "max 1",
            }),
    },
];

interface LogProps{
    onSubmit:(data:any) => void
    currentRow?:any
    dialogId: DialogId
    label: string
    diets: any
    workouts: any
    isSM:boolean
    isLoading:boolean
}

/**
 * Log
 * @return {React.ReactElement}
 */
function Log(props:LogProps):React.ReactElement {
    // pre-populate diets and workouts values in form
    FORM_SCHEMA.slice(1, 3).forEach((item:any) => {
        /* eslint-disable no-param-reassign */
        const values=props[`${item.id}s` as keyof LogProps];
        const detaults=DEFAULT_VALUES[item.id] || [];
        if (item.autocompleteOptions && values) item.autocompleteOptions.options=[...detaults, ...(values.rows.map((row:any) => row.name))];
    });

    const form:IForm=useForm(FORM_SCHEMA, props.currentRow);
    const [imageURL, setImageURL]:[any, Dispatch<SetStateAction<any>>]=useState(null);

    useEffect(() => {
        if (props.currentRow?.imageURL) Util.getFile(props.currentRow?.imageURL, setImageURL);
    }, [imageURL, props.currentRow?.imageURL]);

    /**
     * constructLayout
     * @param {React.ReactElement[]} fields
     * @return {React.ReactElement}
     */
    const constructLayout=(fields:React.ReactElement[]):React.ReactElement => (

        <Dialog
            id={props.dialogId}
            label={props.label}
            onPrimaryAction={props.onSubmit}
            onPrimaryLabel={props.currentRow?"Edit":"Add"}
            isLoading={props.isLoading}
            form={form}
            content={(
                <Grid container direction="row" justifyContent="center" alignItems="center">
                    {fields.map((item:React.ReactElement) => <Grid item xs={12} key={item.key}>{item}</Grid>)}
                    {/* imageURL */}
                    {props.currentRow?.imageURL?(
                        <Grid container direction="column" justifyContent="center" alignItems="center">
                            <Grid item>
                                {imageURL?(
                                    <Paper
                                        elevation={5}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "flex-start",
                                            padding: "6px",
                                            width: "212px",
                                        }}
                                    >
                                        <img alt={`${imageURL}`} src={imageURL} width="200px" />
                                    </Paper>
                                ):<CircularProgress />}
                            </Grid>
                            {imageURL && <Grid item><Link variant="caption" href={imageURL}>Full Screen</Link></Grid>}
                        </Grid>
                    ):null}
                </Grid>
            )}
        />
    );
    return constructLayout(form.fields);
}

Log.defaultProps={
    currentRow: undefined,
};

export default Log;
