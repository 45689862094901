import React, {useEffect} from "react";
import {useTheme, Theme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {Typography, Grid, Link} from "@mui/material";
import {deepPurple} from "@mui/material/colors";
import Box from "../components/generics/Box";
import Macbook from "../components/layout/Macbook";
import {SOCIAL_MEDIA} from "../config";

/**
 * Home
 * @return {React.ReactElement}
 */
function Home():React.ReactElement {
    const theme:Theme=useTheme();
    const isMD =useMediaQuery(theme.breakpoints.down("md"));

    /**
     * onKeydown
     * @param {KeyboardEvent} e
     */
    const onKeydown=(e:KeyboardEvent):void => {
        if (e.ctrlKey && [61, 107, 173, 109, 187, 189].includes(e.keyCode)) e.preventDefault();
    };

    /**
     * onWheel
     * @param {WheelEvent} e
     */
    const onWheel=(e:WheelEvent):void => {
        if (e.ctrlKey) e.preventDefault();
    };

    useEffect(() => {
        document.addEventListener("keydown", onKeydown);
        document.addEventListener("wheel", onWheel, {passive: false});
        return () => {
            document.removeEventListener("keydown", onKeydown);
            document.removeEventListener("wheel", onWheel);
        };
    }, []);

    return (
        <Box>
            {/* toolbar spacer */}
            <Box sx={{...theme.mixins.toolbar}} />
            {/* 3d model */}
            {/* margin left -24 is due padding AND margin top is due padding + toolbar spacer */}
            <Box sx={{position: "absolute", height: "100%", width: "100%", marginLeft: isMD?"0px":"-24px", marginTop: {xs: "-57px", sm: "-64px", md: "-88px", lg: "-88px", xl: "-88px"}}}><Macbook theme={theme} /></Box>
            {/* container */}
            <Grid sx={{marginTop: {xs: "0px", md: "-24px"}}} container direction="row" justifyContent="flex-start" alignItems="flex-start">
                {/* name + subtitle */}
                <Grid item sx={{marginLeft: {xs: "16px", sm: "24px"}, zIndex: 2}}>
                    {/* name */}
                    <Typography variant={isMD?"h3":"h2"} sx={{color: "white", fontWeight: 700, textShadow: "0px 0px 5px #000000"}}>
                        {"Hi, I'm "}
                        <Box sx={{color: deepPurple.A400, display: "inline"}}>Haidar</Box>
                    </Typography>
                    {/* sub text */}
                    <Typography noWrap variant={isMD?undefined:"h6"} sx={{color: "white", textShadow: "0px 0px 10px #000000"}}>
                        I develop applications & user interfaces.
                    </Typography>
                </Grid>
            </Grid>
            {/* social media */}
            <Grid sx={{position: "fixed", bottom: 0, marginBottom: "28px"}} container direction={isMD?"row":"column"} justifyContent={isMD?"center":"flex-end"} alignItems="flex-start">
                {SOCIAL_MEDIA.map((item:any) => <Grid item key={item.url}><Link href={item.url} sx={{color: "white"}}>{item.icon}</Link></Grid>)}
            </Grid>
        </Box>
    );
}

export default Home;
