import React, {forwardRef, ForwardedRef} from "react";
import {Box as BaseBox, BoxProps as BaseBoxProps} from "@mui/material";

interface BoxProps extends BaseBoxProps {
    component?: any
}

/**
 * TS2590: Expression produces a union type that is too complex to represent.
 * https://github.com/haidarzxc/l-profile/issues/11
 * Box component
 * NOTE: component was re-declared due to conflict with Box component exported from "threejs"
 * per above error
 */
const Box=forwardRef((props:BoxProps, ref:ForwardedRef<unknown>):React.ReactElement => <BaseBox ref={ref} {...props} />);

Box.defaultProps={
    component: undefined,
};

export default Box;
