import React from "react";
import {Html, useProgress} from "@react-three/drei";

/**
 * CanvasLoader
 * @return {React.ReactElement}
 */
function CanvasLoader():React.ReactElement {
    const {progress} = useProgress();
    return (
        <Html
            as="div"
            center
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
            }}
        >
            <span className="canvas-loader" />
            <p
                style={{
                    fontSize: 28,
                    color: "#F1F1F1",
                    fontWeight: 800,
                    marginTop: 40,
                    fontFamily: "Poppins",
                }}
            >
                {`${progress.toFixed(2)}%`}
            </p>
        </Html>
    );
}

export default CanvasLoader;
