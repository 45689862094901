import {all, fork, takeLatest} from "redux-saga/effects";

/**
 * fooSaga
 * @return {Generator}
 */
function* fooSaga():Generator {
    // pass
}

/**
 * fooListener
 * @return {Generator}
 */
function* fooListener():Generator { yield takeLatest("@@NON_REGISTERED_ACTION", fooSaga); }

/**
 * confSaga
 * @return {Generator}
 */
function* confSaga():Generator {
    yield all([
        fork(fooListener),
    ]);
}

export default confSaga;
