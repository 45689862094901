import React, {Dispatch, useEffect, useMemo, useState, useCallback} from "react";
import {useTheme, Theme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {Paper} from "@mui/material/";
import {useDispatch, useSelector} from "react-redux";
import {UnknownAction} from "redux";
import {FieldValues} from "react-hook-form";
// icons
import {Restaurant, MonitorWeight, Bento, Difference, DeleteForever, Edit, Add} from "@mui/icons-material";
// components
import Tab, {TabProp} from "../components/generics/Tab";
import SpeedDial from "../components/generics/SpeedDial";
import Grid from "../components/generics/Grid";
import SupplementForm, {FORM_SCHEMA as SUPPLEMENT_FORM_SCHEMA} from "../components/forms/Supplement";
import MealForm, {FORM_SCHEMA as MEAL_FORM_SCHEMA} from "../components/forms/Meal";
import DietForm, {FORM_SCHEMA as DIET_FORM_SCHEMA} from "../components/forms/Diet";
import DeleteForm from "../components/forms/Delete";
import {BaseService} from "../services/firebase";
import {DialogId} from "../store/types/confType";
import {COLLECTIONS, APP_PATHS} from "../config";
import Box from "../components/generics/Box";

const SUPPLEMENT_GRID_COL_DEFS={
    name: {width: "260"},
    company: {width: "120"},
    type: {width: "120"},
};

export const MEAL_GRID_COL_DEFS={
    name: {width: "260"},
    measurement: {width: "130"},
    calories: {width: "90"},
    protein: {width: "90"},
    carbohydrates: {width: "150"},
    fat: {width: "80"},
    sodium: {width: "90"},
    potassium: {width: "110"},
};

const DIET_GRID_COL_DEFS={
    name: {width: "260"},
    type: {width: "120"},
    meals: {width: "250"},
    supplements: {width: "250"},

};

/**
 * Nutrition
 * @return {React.ReactElement}
 */
function Nutrition():React.ReactElement {
    const theme:Theme=useTheme();
    const isMD:boolean=useMediaQuery(theme.breakpoints.down("md"));
    const dispatch:Dispatch<UnknownAction>=useDispatch();
    const currentRow:any=useSelector((state:any) => state.conf.currentRow);
    const [tabIndex, setTabIndex]:any=useState(0);
    const [collection, setCollection]:any=useState(COLLECTIONS.DIET);
    const [supplements, setSupplements]:any=useState(null);
    const [meals, setMeals]:any=useState(null);
    const [diets, setDiets]:any=useState(null);

    const GET_CALLS_PROPS=useMemo(() => (
        [
            {collection: COLLECTIONS.SUPPLEMENT, set: setSupplements, formSchema: SUPPLEMENT_FORM_SCHEMA, gridColDef: SUPPLEMENT_GRID_COL_DEFS},
            {collection: COLLECTIONS.MEAL, set: setMeals, formSchema: MEAL_FORM_SCHEMA, gridColDef: MEAL_GRID_COL_DEFS},
            {collection: COLLECTIONS.DIET, set: setDiets, formSchema: DIET_FORM_SCHEMA, gridColDef: DIET_GRID_COL_DEFS},
        ]
    ), []);

    /**
     * onTabChange
     * @param {any} args
     * @return {void}
     */
    const onTabChange=(index:number):void => {
        if (index===0) setCollection(COLLECTIONS.DIET);
        else if (index===1) setCollection(COLLECTIONS.MEAL);
        else if (index===2) setCollection(COLLECTIONS.SUPPLEMENT);
        dispatch({type: "@@CONF/SET_CURRENT_ROW", currentRow: null});
        setTabIndex(index);
    };

    const getDocs=useCallback(async () => {
        GET_CALLS_PROPS.forEach(async (callProp:any) => {
            const res=await BaseService.list(callProp);
            if (res.error) {
                // display snack message
                dispatch({
                    type: "@@CONF/NOTIFIER_ENQUEUE",
                    notification: {
                        message: res.error.message,
                        options: {variant: "error", action: "DISMISS", persist: false},
                    },
                });
            }
        });
    }, [dispatch, GET_CALLS_PROPS]);

    useEffect(() => {
        getDocs();
    }, [getDocs]);

    // reseting current row
    useEffect(() => (
        () => {
            dispatch({type: "@@CONF/SET_CURRENT_ROW", currentRow: null, dialogId: "NONE"});
        }
    ), [dispatch]);

    const actions:any= [
        {icon: <Add />, name: "Add", onClick: (args:React.MouseEvent<HTMLElement>) => dispatch({type: "@@CONF/SET_DIALOG_ID", dialogId: `${collection.toUpperCase()}_CREATE`})},
        {icon: <Edit />, name: "Edit", onClick: currentRow && ((args:React.MouseEvent<HTMLElement>) => dispatch({type: "@@CONF/SET_DIALOG_ID", dialogId: `${collection.toUpperCase()}_UPDATE`}))},
        {icon: <DeleteForever />, name: "Delete", onClick: currentRow && ((args:React.MouseEvent<HTMLElement>) => dispatch({type: "@@CONF/SET_DIALOG_ID", dialogId: `${collection.toUpperCase()}_DELETE`}))},
    ];

    /**
     * onCall
     * @param {FieldValues} data
     * @return {Promise<void>}
     */
    const onCall= (type:DialogId) => async (data:FieldValues):Promise<void> => {
        let res:any=null;
        // resolve service CRUD
        const serviceMapper:any={
            SUPPLEMENT_CREATE: () => BaseService.post({collection, payload: data}),
            SUPPLEMENT_UPDATE: () => BaseService.update({collection, payload: data, currentRow, foreignKey: {field: "supplements", parentCollection: COLLECTIONS.DIET}}),
            SUPPLEMENT_DELETE: () => BaseService.del({collection, currentRow, foreignKey: {field: "supplements", parentCollection: COLLECTIONS.DIET}}),
            MEAL_CREATE: () => BaseService.post({collection, payload: data}),
            MEAL_UPDATE: () => BaseService.update({collection, payload: data, currentRow, foreignKey: {field: "meals", parentCollection: COLLECTIONS.DIET}}),
            MEAL_DELETE: () => BaseService.del({collection, currentRow, foreignKey: {field: "meals", parentCollection: COLLECTIONS.DIET}}),
            DIET_CREATE: () => BaseService.post({collection, payload: data}),
            DIET_UPDATE: () => BaseService.update({collection, payload: data, currentRow, foreignKey: {field: "diet", parentCollection: COLLECTIONS.LOG, queryOp: "=="}}),
            DIET_DELETE: () => BaseService.del({collection, currentRow, foreignKey: {field: "diet", parentCollection: COLLECTIONS.LOG, queryOp: "=="}}),
        };
        // call service
        res=await serviceMapper[type]();

        if (res.data) {
            // close dialog
            dispatch({type: "@@CONF/SET_DIALOG_ID", dialogId: "NONE"});
            // display snack message
            dispatch({
                type: "@@CONF/NOTIFIER_ENQUEUE",
                notification: {
                    message: res.data.message,
                    options: {variant: "success", action: "DISMISS", persist: false},
                },
            });
            // unset selected row
            if (currentRow) dispatch({type: "@@CONF/SET_CURRENT_ROW", currentRow: null});

            // re-fetch values to update grid view
            if (tabIndex===0) await BaseService.list(GET_CALLS_PROPS[2]);
            else if (tabIndex===1) {
                await BaseService.list(GET_CALLS_PROPS[1]);
                // update Diet since foreign keys live under Diet may have been altered
                if (type!=="MEAL_CREATE") await BaseService.list(GET_CALLS_PROPS[2]);
            } else if (tabIndex===2) {
                await BaseService.list(GET_CALLS_PROPS[0]);
                // update Diet since foreign keys live under Diet may have been altered
                if (type!=="SUPPLEMENT_CREATE") await BaseService.list(GET_CALLS_PROPS[2]);
            }
        } else if (res.error) {
            // display snack message
            dispatch({
                type: "@@CONF/NOTIFIER_ENQUEUE",
                notification: {
                    message: res.error.message,
                    options: {variant: "error", action: "DISMISS", persist: false},
                },
            });
        }
    };

    const tabs:TabProp[]=[
        {
            label: "Diet",
            icon: <MonitorWeight />,
            content: (
                <Box>
                    {/* Grid Instance */}
                    {diets && (
                        <Grid
                            initialState={{
                                columns: {
                                    columnVisibilityModel: {
                                        id: false,
                                    },
                                },
                            }}
                            label="Diets"
                            rows={diets.rows}
                            height={500}
                            columns={diets.columns}
                            paginationModel={{page: 0, pageSize: 25}}
                            pageSizeOptions={[10, 25, 50, 100]}
                            onAddClick={actions[0].onClick}
                            onEditClick={actions[1].onClick}
                            onDeleteClick={actions[2].onClick}
                            path={APP_PATHS.NUTRITION_DETAIL}
                        />
                    )}
                    {/* Create Dialog Instance */}
                    <DietForm onSubmit={onCall("DIET_CREATE")} label="Add Diet" dialogId="DIET_CREATE" meals={meals} supplements={supplements} />
                    {/* Update Dialog Instance */}
                    {currentRow && <DietForm onSubmit={onCall("DIET_UPDATE")} label="Edit Diet" currentRow={currentRow} dialogId="DIET_UPDATE" meals={meals} supplements={supplements} />}
                    {/* Delete Dialog Instance */}
                    {currentRow && <DeleteForm onDelete={onCall("DIET_DELETE")} label="Delete Diet" currentRow={currentRow} dialogId="DIET_DELETE" />}
                </Box>
            ),
        },
        {
            label: "Meal",
            icon: <Restaurant />,
            content: (
                <Box>
                    {/* Grid Instance */}
                    {meals && (
                        <Grid
                            initialState={{
                                columns: {
                                    columnVisibilityModel: {
                                        id: false,
                                    },
                                },
                            }}
                            label="Meal"
                            rows={meals.rows}
                            height={500}
                            columns={meals.columns}
                            paginationModel={{page: 0, pageSize: 25}}
                            pageSizeOptions={[10, 25, 50, 100]}
                            onAddClick={actions[0].onClick}
                            onEditClick={actions[1].onClick}
                            onDeleteClick={actions[2].onClick}
                        />
                    )}
                    {/* Create Dialog Instance */}
                    <MealForm onSubmit={onCall("MEAL_CREATE")} label="Add Meal" dialogId="MEAL_CREATE" />
                    {/* Update Dialog Instance */}
                    {currentRow && <MealForm onSubmit={onCall("MEAL_UPDATE")} label="Edit Meal" currentRow={currentRow} dialogId="MEAL_UPDATE" />}
                    {/* Delete Dialog Instance */}
                    {currentRow && <DeleteForm onDelete={onCall("MEAL_DELETE")} label="Delete Meal" currentRow={currentRow} dialogId="MEAL_DELETE" />}
                </Box>
            ),
        },
        {
            label: "Supplement",
            icon: <Bento />,
            content: (
                <Box>
                    {/* Grid Instance */}
                    {supplements && (
                        <Grid
                            initialState={{
                                columns: {
                                    columnVisibilityModel: {
                                        id: false,
                                    },
                                },
                            }}
                            label="Supplement"
                            rows={supplements.rows}
                            height={500}
                            columns={supplements.columns}
                            paginationModel={{page: 0, pageSize: 25}}
                            pageSizeOptions={[10, 25, 50, 100]}
                            onAddClick={actions[0].onClick}
                            onEditClick={actions[1].onClick}
                            onDeleteClick={actions[2].onClick}
                        />
                    )}
                    {/* Create Dialog Instance */}
                    <SupplementForm onSubmit={onCall("SUPPLEMENT_CREATE")} label="Add Supplement" dialogId="SUPPLEMENT_CREATE" />
                    {/* Update Dialog Instance */}
                    {currentRow && <SupplementForm onSubmit={onCall("SUPPLEMENT_UPDATE")} label="Edit Supplement" currentRow={currentRow} dialogId="SUPPLEMENT_UPDATE" />}
                    {/* Delete Dialog Instance */}
                    {currentRow && <DeleteForm onDelete={onCall("SUPPLEMENT_DELETE")} label="Delete Supplement" currentRow={currentRow} dialogId="SUPPLEMENT_DELETE" />}
                </Box>
            ),
        },
    ];

    return (
        <Box>
            {isMD && (
                <>
                    <SpeedDial ariaLabel="nutrition-controller" actions={actions} icon={<Difference />} />
                    <Tab onTabChange={onTabChange} tabIndex={tabIndex} data={tabs} sticky />
                </>
            )}
            {!isMD && <Paper><Tab onTabChange={onTabChange} tabIndex={tabIndex} data={tabs} /></Paper>}
        </Box>
    );
}

export default Nutrition;
