import {Dispatch} from "react";
import {collection, query, where, getDocs, doc, getDoc, QuerySnapshot, writeBatch, Query, DocumentData, Timestamp} from "firebase/firestore";
import {uploadBytes, ref, UploadResult, deleteObject, getDownloadURL} from "firebase/storage";
import moment from "moment";
import {FieldValues} from "react-hook-form";
import {firestore, storage} from "../../config";
import {Payload, ActionType, CheckListItem} from "../../store/types/confType";
import {FILE_UPLOAD_ERROR, FILE_DELETE_ERROR} from "./BaseService";

interface IsDuplicateArgs {
    name:string
    docID?:string
    collection: string
}

interface IsExistsArgs {
    id:string
    collection:string
}

export type QueryOp="array-contains"|"==";

interface ForeignKey{
    key: string
    collection: string
    op: "DELETE"|"UPDATE"
    value: string
    newValue?: string
    queryOp?: QueryOp
}

/**
 * isDuplicate
 * @param {IsDuplicateArgs} args
 * @return {Promise<boolean>}
 */
const isDuplicate=async (args:IsDuplicateArgs):Promise<boolean> => {
    // conditions
    let {name}:any=args;
    if (name instanceof Date) name=Timestamp.fromDate(name);
    const NAME_COND=where("name", "==", name);
    const DOCID_COND=where("__name__", "!=", args.docID);

    const docRef = collection(firestore, args.collection);
    let q=query(docRef, NAME_COND);
    if (args.docID) q=query(docRef, NAME_COND, DOCID_COND);
    const querySnapshot = await getDocs(q);
    return querySnapshot.empty;
};

/**
 * isExists
 * @param {IsExistsArgs} args
 * @return {Promise<boolean>}
 */
const isExists= async (args: IsExistsArgs):Promise<boolean> => {
    const docSnap=await getDoc(doc(firestore, args.collection, args.id));
    return docSnap.exists();
};

/**
 * updateRemoveForeignKey
 * @param {ForeignKey} args
 * @return {void}
 */
const updateRemoveForeignKey= async (args:ForeignKey): Promise<void> => {
    const batchArray:any=[];
    let c=0;
    let idx=0;
    batchArray.push(writeBatch(firestore));

    // get all docs
    let q:Query<DocumentData, DocumentData>=collection(firestore, args.collection);
    // query when there is a query op
    if (args.queryOp) q=query(collection(firestore, args.collection), where(args.key, args.queryOp, args.value));
    const querySnapshot=await getDocs(q)
        .then((qShot:QuerySnapshot) => qShot)
        .catch((error:any) => error);

    querySnapshot.forEach((document:any) => {
        let field=document.data()[args.key];
        const isArray=Array.isArray(field);

        if (args.op==="DELETE") {
            // string[] OR CheckListItem[]
            if (isArray) {
                field=field.filter((item:string|CheckListItem) => {
                    // is CheckListItem type
                    if ((typeof item==="object" && "key" in item)) return item.key!==args.value;
                    // is string type
                    return item!==args.value;
                });
            } else field=null;
        } else if (args.op==="UPDATE") {
            // string[] OR CheckListItem[]
            if (isArray) {
                field=field.map((item:string|CheckListItem) => {
                    // is CheckListItem type
                    if ((typeof item==="object" && "key" in item) && item.key===args.value) return {key: args.newValue, value: item.value} as CheckListItem;
                    // is string type
                    if (typeof item==="string" && item===args.value) return args.newValue;
                    return item;
                });
            } else field=args.newValue;
        }

        batchArray[idx].update(doc(firestore, args.collection, document.id), {[args.key]: field});
        c+=1;
        if (c===499) {
            batchArray.push(writeBatch(firestore));
            idx+=1;
            c=0;
        }
    });

    if (querySnapshot.empty) return;
    batchArray.forEach(async (batch:any) => (
        batch.commit()
            .then(() => {
                // console.log({data: {code: "Util/batch-commited"}});
            })
            .catch((error:any) => {
                // console.log({error: {code: "Util/batch-not-commited", message: error}});
            })
    ));
};

/**
   * deleteFile
   * @param {string} path
   * @return {Promise<boolean|null>}
   */
const deleteFile=async (path?:string):Promise<boolean|null> => {
    // return when no path exists!
    if (!path) return null;
    // delete file
    return deleteObject(ref(storage, path)).then(() => true).catch((error:any) => false);
};

/**
 * uploadFile
 * @param {any} file
 * @param {string} path
 * @param {string} currentPath
 * @return {Promise<Payload>}
 */
const uploadFile=async (file:any, path:string, currentPath?:string):Promise<Payload> => {
    // return if no file exists
    if (!file) return {data: {metadata: {fullPath: currentPath || ""}}};

    // delete current file exists on storage
    if ((await deleteFile(currentPath))===false) return FILE_DELETE_ERROR;

    // push new file
    return uploadBytes(ref(storage, path), file)
        .then((snapshot:UploadResult) => ({data: {metadata: snapshot.metadata}}))
        .catch((error:any) => FILE_UPLOAD_ERROR);
};

/**
 * getFile
 * @param {string} path
 * @return {Promise<void>}
 */
const getFile=async (path:string, set:Dispatch<any>):Promise<void> => {
    // return if no path exists
    if (!path) {
        set(null);
        return;
    }
    // get file URL
    getDownloadURL(ref(storage, path)).then((url) => set(url)).catch((error) => set(null));
};

/**
 * resolveTimestamp
 * https://firebase.google.com/docs/reference/js/v8/firebase.firestore.Timestamp#fromdate
 * @param {FieldValues} values
 * @return {FieldValues}
 */
const resolveTimestamp=(values:FieldValues):FieldValues => {
    const newValues:FieldValues={...values};
    Object.keys(newValues).forEach((key:string) => { if (newValues[key] instanceof Date) newValues[key]=Timestamp.fromDate(newValues[key]); });
    return newValues;
};

/**
 * resolveFilterAction
 * @param {ActionType} filter
 * @param {FieldValues} form
 * @return {FieldValues|null}
 */
const resolveFilterAction=(action:ActionType, form?:FieldValues):FieldValues|null => {
    // resolve "start" and "end" dates
    if (action==="this-year") {
        return resolveTimestamp({start: moment().startOf("year").toDate(), end: moment().endOf("year").toDate()});
    } if (action==="last-year") {
        return resolveTimestamp({start: moment().subtract(1, "years").startOf("year").toDate(), end: moment().subtract(1, "years").endOf("year").toDate()});
    } if (action==="range" && form) {
        return resolveTimestamp({start: form.start, end: form.end});
    }
    return null;
};

export const Util:any={
    isDuplicate,
    isExists,
    updateRemoveForeignKey,
    deleteFile,
    uploadFile,
    getFile,
    resolveFilterAction,
    resolveTimestamp,
};
