import React, {useState, Suspense, useRef} from "react";
import * as THREE from "three";
import {Canvas, useFrame} from "@react-three/fiber";
import {OrbitControls, useGLTF, Html, Environment, ContactShadows, PerformanceMonitor, useProgress} from "@react-three/drei";
import {useTheme, Theme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {LayerMaterial, Color, Depth} from "lamina";
import {grey} from "@mui/material/colors";
import CanvasLoader from "../generics/CanvasLoader";
import HeroView from "./HeroView";

interface MackbookProps{
    theme:Theme
}

interface ComputerProps{
    isMD:boolean
}

/**
 * Computer
 * @return {React.ReactElement}
 */
function Computer(props:ComputerProps):React.ReactElement {
    const computer = useGLTF("./macbook_pro/scene.gltf");
    const group:any= useRef();
    useFrame((state:any) => {
        const t = state.clock.getElapsedTime();
        group.current.rotation.x = THREE.MathUtils.lerp(group.current.rotation.x, Math.cos(t / 2) / 20 + 0.25, 0.1);
        group.current.rotation.y = THREE.MathUtils.lerp(group.current.rotation.y, Math.sin(t / 4) / 20, 0.1);
        group.current.rotation.z = THREE.MathUtils.lerp(group.current.rotation.z, Math.sin(t / 8) / 20, 0.1);
        group.current.position.y = THREE.MathUtils.lerp(group.current.position.y, (-2 + Math.sin(t / 2)) / 20, 0.1);
    });
    return (
        <group ref={group} {...props}>
            <mesh>
                {/* eslint-disable react/no-unknown-property */}
                <hemisphereLight intensity={0.15} groundColor="black" />
                <spotLight
                    position={[-20, 50, 10]}
                    angle={0.12}
                    penumbra={1}
                    intensity={1}
                    castShadow
                    shadow-mapSize={1024}
                />
                <pointLight intensity={1} />
                <Html zIndexRange={[0]} className="content" rotation-x={0.4} rotation-y={Math.PI} position={[0, 2.695, 1.05]} transform occlude>
                    <div className="wrapper" onPointerDown={(e) => e.stopPropagation()}>
                        <HeroView />
                    </div>
                </Html>
                <primitive
                    object={computer.scene}
                    scale={2.3}
                    position={[0, -5.5, 0]}
                    rotation={[0, 0, 0]}
                />
            </mesh>
        </group>
    );
}

/**
 * Macbook
 * @return {React.ReactElement}
 */
function Macbook(props:MackbookProps):React.ReactElement {
    const theme = useTheme();
    const isMD = useMediaQuery(theme.breakpoints.down("md"));
    const [degraded, degrade] = useState(false);
    const {errors} = useProgress();

    if (errors.length!==0) {
        console.log(errors);
        return <div />;
    }

    return (
        <Canvas
            shadows
            dpr={[1, 2]}
            camera={{position: [-5, 0, -15], fov: 55}}
        >
            {[{v: [-6, 15, 10], k: 0}, {v: [10, 10, 10], k: 1}, {v: [-15, 35, 10], k: 2}].map((item:any) => (
                <pointLight key={item.k} position={item.v} intensity={1.5} />
            ))}
            <Suspense fallback={<CanvasLoader />}>
                <group scale={1.0} position={[0, -1.5, 0]} rotation={[-0.3, 0, 0]}><Computer isMD={isMD} /></group>
                <PerformanceMonitor onDecline={() => degrade(true)} />
                <Environment frames={degraded ? 1 : Infinity} resolution={256} background blur={1}>
                    <mesh scale={100}>
                        <sphereGeometry args={[1, 64, 64]} />
                        <LayerMaterial side={THREE.BackSide}>
                            <Color color={grey[400]} alpha={1} mode="normal" />
                            <Depth colorA={grey.A700} colorB="black" alpha={0.5} mode="normal" near={0} far={300} origin={[100, 100, 100]} />
                        </LayerMaterial>
                    </mesh>
                </Environment>
            </Suspense>
            <ContactShadows position={[0, -4.5, 0]} scale={20} blur={2} far={4.5} />
            <OrbitControls
                enablePan={false}
                enableZoom={false}
                maxPolarAngle={Math.PI / 3}
                minPolarAngle={Math.PI / 3}
            />
        </Canvas>
    );
}

export default Macbook;
