import React, {useState, Dispatch, SetStateAction, useEffect} from "react";
import {onAuthStateChanged} from "firebase/auth";
import {auth as authBase} from "../../config";

export interface AuthProps{
    isLoading: boolean
    isAuthenticated: boolean
    user: any
}

/**
 * useAuth
 * @return {AuthProps}
 */
const useAuth=():AuthProps => {
    const [auth, setAuth]:[AuthProps, Dispatch<SetStateAction<AuthProps>>]=useState<AuthProps>({
        isLoading: true,
        isAuthenticated: false,
        user: null,
    });

    useEffect(() => {
        onAuthStateChanged(authBase, (user:any) => {
            if (user) setAuth({isLoading: false, isAuthenticated: true, user});
            else setAuth({isLoading: false, isAuthenticated: false, user: null});
        });
    }, [auth.user, auth.isAuthenticated, auth.isLoading]);

    return auth;
};

export default useAuth;
