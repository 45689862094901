import {Dispatch} from "react";
import {collection, getDocs, addDoc, setDoc, doc, deleteDoc, query, where} from "firebase/firestore";
import {FieldValues} from "react-hook-form";
import {firestore, auth} from "../../config";
import {formatList, resolveFileName} from "../../handler/firebase";
import {Util, QueryOp} from "./index";
import {FormSchema, Payload, ActionType} from "../../store/types/confType";

/**
 * toUpper
 * @param {string} str
 * @return {string}
 */
const toUpper=(str:string):string => (str.charAt(0).toUpperCase()+str.slice(1));

/**
 * resolveMessage
 * @param {string} name
 * @param {Payload} message
 * @return {Payload}
 */
const resolveMessage=(name:string, message:Payload):Payload => {
    // clone
    const content:any=JSON.parse(JSON.stringify(message));
    // update collection name
    Object.keys(content).forEach((key:string) => {
        content[key].code=content[key].code.replace("{COLLECTION}", name);
        content[key].message=content[key].message.replace("{COLLECTION}", toUpper(name));
    });
    return content;
};

// general messages
const DOCUMENT_DOES_NOT_EXIST:Payload={error: {code: "{COLLECTION}/document-not-found", message: "{COLLECTION} does not exist"}};
const DOCUMENT_DOES_EXISTS:Payload={error: {code: "{COLLECTION}/document-already-exists", message: "{COLLECTION} already exists"}};
const INTERNAL_SERVER_ERROR:Payload={error: {code: "{COLLECTION}/internal-server-error", message: "{COLLECTION} service not responding!"}};
export const FILE_DELETE_ERROR:Payload={error: {code: "file-delete-error", message: "Unable to delete file!"}};
export const FILE_UPLOAD_ERROR:Payload={error: {code: "file-upload-error", message: "unable to upload file!"}};
const COMMENT_SET_SUCCESS:Payload={data: {code: "{COLLECTION}/comment-set-success", message: "Notes added to {COLLECTION}!"}};
const COMMENT_SET_ERROR:Payload={error: {code: "{COLLECTION}/comment-set-error", message: "Unable to add Notes {COLLECTION}!"}};

// create messages
const DOCUMENT_CREATED:Payload={data: {code: "{COLLECTION}/document-created", message: "{COLLECTION} created"}};
const DOCUMENT_NOT_CREATED:Payload={error: {code: "{COLLECTION}/document-not-created", message: "{COLLECTION} is not created"}};

// update messages
const DOCUMENT_UPDATED:Payload={data: {code: "{COLLECTION}/document-updated", message: "{COLLECTION} updated"}};
// const DOCUMENT_NOT_UPDATED:Payload={error: {code: "{COLLECTION}/document-not-updated", message: "{COLLECTION} is not updated"}};

// delete messages
const DOCUMENT_DELETED:Payload={data: {code: "{COLLECTION}/document-deleted", message: "{COLLECTION} deleted"}};
// const DOCUMENT_NOT_DELETED:Payload={error: {code: "{COLLECTION}/document-not-deleted", message: "{COLLECTION} is not deleted"}};

// fetch messages
const DOCUMENTS_FETCH:Payload={data: {code: "{COLLECTION}/documents-fetched", message: "{COLLECTION} Fetch Success"}};
const DOCUMENTS_NOT_FETCHED:Payload={error: {code: "{COLLECTION}/documents-not-fetched", message: "{COLLECTION} Fetch Error"}};

interface ListArgs{
    collection:string
    set:Dispatch<any>
    formSchema: FormSchema[]
    gridColDef:any
    filterAction?:ActionType
    payload?:FieldValues
    in?:string[]
}

interface PostArgs{
    collection:string
    payload:FieldValues
    onSuccess?:Payload
    onFailure?:Payload
}

interface ForeignKey{
    field:string
    parentCollection:string
    queryOp: QueryOp
}

interface UpdateArgs{
    collection:string
    payload:FieldValues
    currentRow:any
    onSuccess?:Payload
    foreignKey?:ForeignKey
}

interface DeleteArgs{
    collection:string
    currentRow:any
    onSuccess?:Payload
    foreignKey?:ForeignKey
}

interface CommentArgs{
    collection:string
    payload:FieldValues
    currentRow:any
}

/**
 * list
 * @param {ListArgs} args
 * @return {Promise<Payload>}
 */
const list= async (args:ListArgs):Promise<Payload> => {
    try {
        const res:Payload=resolveMessage(args.collection, DOCUMENTS_FETCH);
        if ((!args.in && !args.filterAction)) {
            // fetch all docs if no args [in, filterAction]
            res.data.result=await getDocs(collection(firestore, args.collection));
        } else if (args.filterAction) {
            // fetch using a FilterType -- Routine view specific
            const range=Util.resolveFilterAction(args.filterAction, args.payload);
            if (args.filterAction==="all") res.data.result=await getDocs(collection(firestore, args.collection));
            else if (range) {
                const q=query(collection(firestore, args.collection), where("name", ">=", range.start), where("name", "<=", range.end));
                res.data.result=await getDocs(q);
            }
        } else if (args.in) {
            // fetch using in op
            const q=query(collection(firestore, args.collection), where("name", "in", args.in));
            res.data.result=await getDocs(q);
        }
        // update state if set exists
        if (args.set && res.data.result) args.set(formatList(res.data.result, args.formSchema, args.gridColDef));
        return res;
    } catch (e) {
        console.log(e);
        return resolveMessage(args.collection, DOCUMENTS_NOT_FETCHED);
    }
};

/**
 * post
 * @param {PostArgs} args
 * @return {Promise<Payload>}
 */
const post= async (args:PostArgs):Promise<Payload> => {
    try {
        // check if name is duplicate
        if (!(await Util.isDuplicate({collection: args.collection, name: args.payload.name}))) return resolveMessage(args.collection, DOCUMENT_DOES_EXISTS);

        let res;
        // push imageURL
        if (Array.isArray(args.payload.imageURL)) {
            res= await Util.uploadFile(
                args.payload.imageURL[0],
                `${args.collection}s/${auth.currentUser?.uid}/${resolveFileName(args.payload.name, args.payload.imageURL[0]?.name)}`,
            );
            if (res?.error) return res;
            /* eslint-disable no-param-reassign */
            args.payload.imageURL=res.data.metadata.fullPath;
        }

        // add doc
        res= await addDoc(collection(firestore, args.collection), Util.resolveTimestamp(args.payload));
        if (res.id) return args.onSuccess?args.onSuccess:resolveMessage(args.collection, DOCUMENT_CREATED);
        return args.onFailure?args.onFailure:resolveMessage(args.collection, DOCUMENT_NOT_CREATED);
    } catch (e) {
        console.log(e);
        return resolveMessage(args.collection, INTERNAL_SERVER_ERROR);
    }
};

/**
 * update
 * @param {UpdateArgs} args
 * @return {Promise<Payload>}
 */
const update= async (args:UpdateArgs): Promise<Payload> => {
    try {
        // check if document exists
        if (!await Util.isExists({id: args.currentRow.id, collection: args.collection})) return resolveMessage(args.collection, DOCUMENT_DOES_NOT_EXIST);
        // check if name is duplicate
        if (!(await Util.isDuplicate({collection: args.collection, name: args.payload.name, docID: args.currentRow.id}))) return resolveMessage(args.collection, DOCUMENT_DOES_EXISTS);

        // update foreign key living in parent collection if different
        if (args.foreignKey && (args.currentRow.name!==args.payload.name)) {
            await Util.updateRemoveForeignKey({
                op: "UPDATE",
                key: args.foreignKey.field,
                value: args.currentRow.name,
                newValue: args.payload.name,
                collection: args.foreignKey.parentCollection,
                queryOp: args.foreignKey.queryOp,
            });
        }

        // update imageURL
        if (Array.isArray(args.payload.imageURL)) {
            const res= await Util.uploadFile(
                args.payload.imageURL[0],
                `${args.collection}s/${auth.currentUser?.uid}/${resolveFileName(args.payload.name, args.payload.imageURL[0]?.name)}`,
                args.currentRow.imageURL,
            );
            if (res?.error) return res;
            /* eslint-disable no-param-reassign */
            args.payload.imageURL=res.data.metadata.fullPath;
        }

        // set doc
        await setDoc(doc(firestore, args.collection, args.currentRow.id), Util.resolveTimestamp(args.payload), {merge: true});
        return args.onSuccess?args.onSuccess:resolveMessage(args.collection, DOCUMENT_UPDATED);
    } catch (e) {
        console.log(e);
        return resolveMessage(args.collection, INTERNAL_SERVER_ERROR);
    }
};

/**
 * del
 * @param {DeleteArgs} args
 * @return {Promise<Payload>}
 */
const del= async (args:DeleteArgs): Promise<Payload> => {
    try {
        // check if document exists
        if (!await Util.isExists({id: args.currentRow.id, collection: args.collection})) return resolveMessage(args.collection, DOCUMENT_DOES_NOT_EXIST);

        // remove foreign key living in parent collection
        if (args.foreignKey) {
            await Util.updateRemoveForeignKey({
                op: "DELETE",
                key: args.foreignKey.field,
                value: args.currentRow.name,
                collection: args.foreignKey.parentCollection,
                queryOp: args.foreignKey.queryOp,
            });
        }

        // delete imageURL
        if ((await Util.deleteFile(args.currentRow.imageURL))===false) return FILE_DELETE_ERROR;

        await deleteDoc(doc(firestore, args.collection, args.currentRow.id));
        return args.onSuccess?args.onSuccess:resolveMessage(args.collection, DOCUMENT_DELETED);
    } catch (e) {
        console.log(e);
        return resolveMessage(args.collection, INTERNAL_SERVER_ERROR);
    }
};

/**
 * setComment
 * @param {CommentArgs} args
 * @return {Payload}
 */
const setComment= async (args:CommentArgs): Promise<Payload> => {
    try {
        // set doc
        await setDoc(doc(firestore, args.collection, args.currentRow.id), {comment: JSON.stringify(args.payload)}, {merge: true});
        return resolveMessage(args.collection, COMMENT_SET_SUCCESS);
    } catch (e) {
        console.log(e);
        return resolveMessage(args.collection, COMMENT_SET_ERROR);
    }
};

export const BaseService:any={
    list,
    post,
    update,
    del,
    setComment,
};
