import React from "react";
import {Grid} from "@mui/material/";
import Joi from "joi";
import useForm, {IForm} from "../generics/useForm";
import {FormSchema, DialogId} from "../../store/types/confType";
import {MEAL_MEASUREMENT_OPTIONS} from "../../config";
import Dialog from "../generics/Dialog";

export const FORM_SCHEMA:FormSchema[]=[
    {
        id: "name",
        type: "text",
        label: "Name",
        joiOptions: Joi.string().required()
            .messages({
                "string.empty": "Set a Name",
            }),
    },
    {
        id: "measurement",
        type: "autocomplete",
        label: "Measurement",
        autocompleteOptions: {
            options: MEAL_MEASUREMENT_OPTIONS,
            multiple: false,
        },
        joiOptions: Joi.string().valid(...MEAL_MEASUREMENT_OPTIONS).required()
            .messages({
                "any.required": "Select",
                "any.only": "Select",
            }),
    },
    {
        id: "calories",
        type: "text",
        label: "calories",
        joiOptions: Joi.number().required()
            .messages({
                "number.base": "Set a Number",
            }),
    },
    {
        id: "protein",
        type: "text",
        label: "Protein",
        joiOptions: Joi.number().required()
            .messages({
                "number.base": "Set a Number",
            }),
    },
    {
        id: "carbohydrates",
        type: "text",
        label: "Carbohydrates",
        joiOptions: Joi.number().required()
            .messages({
                "number.base": "Set a Number",
            }),
    },
    {
        id: "fat",
        type: "text",
        label: "Fat",
        joiOptions: Joi.number().required()
            .messages({
                "number.base": "Set a Number",
            }),
    },
    {
        id: "sodium",
        type: "text",
        label: "Sodium",
        joiOptions: Joi.number().required()
            .messages({
                "number.base": "Set a Number",
            }),
    },
    {
        id: "potassium",
        type: "text",
        label: "Potassium",
        joiOptions: Joi.number().required()
            .messages({
                "number.base": "Set a Number",
            }),
    },
];

interface MealProps{
    onSubmit:(data:any) => void
    currentRow?:any
    dialogId: DialogId
    label: string
}

/**
 * Meal
 * @return {React.ReactElement}
 */
function Meal(props:MealProps):React.ReactElement {
    const form:IForm=useForm(FORM_SCHEMA, props.currentRow);
    /**
     * constructLayout
     * @param {React.ReactElement[]} fields
     * @return {React.ReactElement}
     */
    const constructLayout=(fields:React.ReactElement[]):React.ReactElement => (

        <Dialog
            id={props.dialogId}
            label={props.label}
            onPrimaryAction={props.onSubmit}
            onPrimaryLabel={props.currentRow?"Edit":"Add"}
            form={form}
            content={(
                <Grid container spacing={0} direction="row" justifyContent="center" alignItems="center">
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid container direction="row" justifyContent="center" alignItems="center" rowSpacing={1} columnSpacing={{xs: 2, sm: 2, md: 2}}>
                            <Grid item xs={12}>{fields[0]}</Grid>
                            {fields.slice(1).map((item:React.ReactElement) => <Grid item xs={6} sm={4} md={4} lg={4} xl={4} key={item.key}>{item}</Grid>)}
                        </Grid>
                    </Grid>
                </Grid>
            )}
        />
    );
    return constructLayout(form.fields);
}

Meal.defaultProps={
    currentRow: undefined,
};

export default Meal;
