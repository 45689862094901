import React from "react";
import {Tabs as TabsBase, Tab as TabBase} from "@mui/material";
import SwipeableViews from "react-swipeable-views";
import AppBar from "@mui/material/AppBar";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {grey} from "@mui/material/colors";
import Box from "./Box";

interface TabPanelProps {
    children: React.ReactNode;
    dir: string;
    index: number;
    value: number;
}

/**
 * TabPanel
 * @param {TabPanelProps} props
 * @return {React.ReactElement}
 */
function TabPanel(props: TabPanelProps):React.ReactElement {
    const {children, value, index, ...other} = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            {...other}
        >
            {value===index && (
                <Box sx={{p: 3}}>
                    {children}
                </Box>
            )}
        </div>
    );
}

export interface TabProp {
    label: string
    icon: React.ReactElement
    content: React.ReactElement
}

interface IProps {
    data:TabProp[]
    sticky?:boolean
    tabIndex: number
    onTabChange: (args: number) => void
}

/**
 * Tab
 * @return {React.ReactElement}
 */
function Tab(props: IProps):React.ReactElement {
    const theme = useTheme();
    const isMD = useMediaQuery(theme.breakpoints.down("md"));

    /**
     * onChange
     * @param {React.SyntheticEvent} event
     * @param {number} newValue
     */
    const onChange = (event: React.SyntheticEvent, newValue: number):void => {
        props.onTabChange(newValue);
    };

    /**
     * onChangeIndex
     * @param {number} index
     */
    const onChangeIndex = (index: number):void => {
        props.onTabChange(index);
    };

    return (
        <Box sx={{bgcolor: "background.paper", width: "100%"}}>
            <AppBar
                position={props.sticky&&isMD?"fixed":"static"}
                sx={props.sticky
                    ?{bgcolor: grey.A700, top: {xs: "auto", md: "inherit"}, bottom: {xs: 0, md: "inherit"}}
                    :{bgcolor: grey.A700}}
            >
                <TabsBase
                    sx={{minHeight: "auto"}}
                    centered={isMD}
                    value={props.tabIndex}
                    onChange={onChange}
                    indicatorColor="secondary"
                    textColor="inherit"
                >
                    {props.data.map((item:TabProp) => (
                        <TabBase
                            key={`tab-${item.label}`}
                            sx={{minHeight: "auto"}}
                            icon={item.icon}
                            iconPosition="start"
                            label={item.label}
                        />
                    ))}
                </TabsBase>
            </AppBar>
            <SwipeableViews
                axis={theme.direction ==="rtl" ?"x-reverse":"x"}
                index={props.tabIndex}
                onChangeIndex={onChangeIndex}
            >
                {props.data.map((item:TabProp, index:number) => (
                    <TabPanel
                        key={`tab-panel-${item.label}`}
                        value={props.tabIndex}
                        index={index}
                        dir={theme.direction}
                    >
                        {item.content}
                    </TabPanel>
                ))}
            </SwipeableViews>
        </Box>
    );
}

Tab.defaultProps={
    sticky: false,
};

export default Tab;
