import React from "react";
import {Grid} from "@mui/material/";
import Joi from "joi";
import useForm, {IForm} from "../generics/useForm";
import {FormSchema, DialogId} from "../../store/types/confType";
import {EXERCISE_TYPE_OPTIONS, EXERCISE_EQUIPMENT_OPTIONS, EXERCISE_MUSCLE_GROUP_OPTIONS} from "../../config";
import Dialog from "../generics/Dialog";

export const FORM_SCHEMA:FormSchema[]=[
    {
        id: "name",
        type: "text",
        label: "Name",
        joiOptions: Joi.string().required()
            .messages({
                "string.empty": "Set a Name",
            }),
    },
    {
        id: "type",
        type: "autocomplete",
        label: "Type",
        autocompleteOptions: {
            options: EXERCISE_TYPE_OPTIONS,
            multiple: false,
        },
        joiOptions: Joi.string().valid(...EXERCISE_TYPE_OPTIONS).required()
            .messages({
                "any.required": "Select",
                "any.only": "Select",
            }),
    },
    {
        id: "equipment",
        type: "autocomplete",
        label: "Equipment",
        autocompleteOptions: {
            options: EXERCISE_EQUIPMENT_OPTIONS,
            multiple: false,
        },
        joiOptions: Joi.string().valid(...EXERCISE_EQUIPMENT_OPTIONS).required()
            .messages({
                "any.required": "Select",
                "any.only": "Select",
            }),
    },
    {
        id: "muscle_group",
        type: "autocomplete",
        label: "Muscle Group",
        autocompleteOptions: {
            options: EXERCISE_MUSCLE_GROUP_OPTIONS,
            multiple: false,
        },
        joiOptions: Joi.string().valid(...EXERCISE_MUSCLE_GROUP_OPTIONS).required()
            .messages({
                "any.required": "Select",
                "any.only": "Select",
            }),
    },
];

interface ExerciseProps{
    onSubmit:(data:any) => void
    currentRow?:any
    dialogId: DialogId
    label: string
}

/**
 * Exercise
 * @return {React.ReactElement}
 */
function Exercise(props:ExerciseProps):React.ReactElement {
    const form:IForm=useForm(FORM_SCHEMA, props.currentRow);
    /**
     * constructLayout
     * @param {React.ReactElement[]} fields
     * @return {React.ReactElement}
     */
    const constructLayout=(fields:React.ReactElement[]):React.ReactElement => (

        <Dialog
            id={props.dialogId}
            label={props.label}
            onPrimaryAction={props.onSubmit}
            onPrimaryLabel={props.currentRow?"Edit":"Add"}
            form={form}
            content={(
                <Grid container spacing={0} direction="row" justifyContent="center" alignItems="center">
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid container rowSpacing={1} columnSpacing={{xs: 2, sm: 2, md: 2}}>
                            {fields.map((item:React.ReactElement) => <Grid item xs={12} key={item.key}>{item}</Grid>)}
                        </Grid>
                    </Grid>
                </Grid>
            )}
        />
    );
    return constructLayout(form.fields);
}

Exercise.defaultProps={
    currentRow: undefined,
};

export default Exercise;
