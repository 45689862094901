import React, {useState, SetStateAction, Dispatch} from "react";
import {useDispatch} from "react-redux";
// mui
import {styled} from "@mui/material/styles";
import {
    CssBaseline,
    Drawer,
    Typography,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    IconButton,
    AppBar as MuiAppBar,
    Toolbar,
    Divider,
    Button,
} from "@mui/material";
import {Link, useLocation} from "react-router-dom";
// mui icons
import MenuIcon from "@mui/icons-material/Menu";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import menuItems, {IMenuItem, SubItem} from "./menuItems";
import {APP_PATHS} from "../../config";
import {UserService} from "../../services/firebase";
import {AuthProps} from "../generics/useAuth";
import Box from "../generics/Box";

export interface OpenStatus {
    [key:string]:any
    profile:boolean
    mobile:boolean
}

const DrawerHeader = styled("div")(({theme}) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
}));

/**
 * wrapPath
 * @param {any} props
 * @param {string} path
 * @return {any}
 */
const wrapPath=(props:any, path?:string):any => {
    if (!path) return props;
    const obj={...props};
    obj.component=Link;
    obj.to=path;
    return obj;
};

interface MenuBarProps{
    auth:AuthProps
}

/**
 * MenuBar
 * @return {React.ReactElement}
 */
function MenuBar(menuBarProps:MenuBarProps):React.ReactElement {
    const dispatch=useDispatch();
    const [open, useOpen]=useState({profile: false, mobile: false});
    const location=useLocation();

    /**
     * onMenuClickMethod
     * @param {string} key
     * @param {Dispatch<SetStateAction<OpenStatus>>} update
     * @return {(args:React.MouseEvent<HTMLElement>):void}
     */
    const onMenuClickMethod=(key:string, update:Dispatch<SetStateAction<OpenStatus>>) => (args:React.MouseEvent<HTMLElement>) => {
        const obj:OpenStatus={...open};
        obj[key]=(!obj[key]);
        update(obj);
    };

    /**
     * constructMobileMenu
     * @return {React.ReactElement}
     */
    const constructMobileMenu=(items:IMenuItem[]):React.ReactElement => (
        <Drawer
            anchor="right"
            open={open.mobile}
            onClose={onMenuClickMethod("mobile", useOpen)}
        >
            <DrawerHeader>
                <IconButton onClick={onMenuClickMethod("mobile", useOpen)}>
                    <ChevronRightIcon />
                </IconButton>
            </DrawerHeader>
            <Divider />
            <List>
                {items.map((item:IMenuItem) => {
                    if (!item.type) return null;
                    if (item.key==="PROFILE" && item.subItems) {
                        return item.subItems.map((subItem:SubItem) => {
                            const props:any=wrapPath({onClick: subItem.onClick("mobile", useOpen), key: `mobile-menu-item-profile-${subItem.path}`}, subItem.path);
                            return (
                                <ListItem button {...props}>
                                    <ListItemIcon>{subItem.icon}</ListItemIcon>
                                    <ListItemText primary={subItem.label} />
                                </ListItem>
                            );
                        });
                    }
                    const props:any=wrapPath({onClick: onMenuClickMethod("mobile", useOpen), key: `mobile-menu-item-${item.key}`}, item.path);
                    return (
                        <ListItem button {...props}>
                            <ListItemIcon>{item.icon}</ListItemIcon>
                            <ListItemText primary={item.label} />
                        </ListItem>
                    );
                })}
            </List>
        </Drawer>
    );

    /**
     * constructProfileMenu
     * @param {IMenuItem} item
     * @return {React.ReactElement|null}
     */
    const constructProfileMenu=(item:IMenuItem):React.ReactElement|null => {
        if (!item.type) return null;
        return (
            <Drawer
                anchor="right"
                open={open.profile}
                onClose={onMenuClickMethod("profile", useOpen)}
            >
                <DrawerHeader>
                    <IconButton onClick={onMenuClickMethod("profile", useOpen)}>
                        <ChevronRightIcon />
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    {item.subItems && item.subItems.map((subItem:SubItem) => {
                        const props:any=wrapPath({onClick: subItem.onClick(item.key.toLowerCase(), useOpen), key: `${subItem.label}-${subItem.path}`}, subItem.path);
                        return (
                            <ListItem button {...props}>
                                <ListItemIcon>{subItem.icon}</ListItemIcon>
                                <ListItemText primary={subItem.label} />
                            </ListItem>
                        );
                    })}
                </List>
            </Drawer>
        );
    };

    /**
     * constructMenuItems
     * @param {IMenuItems} items
     * @return {React.ReactElement}
     */
    const constructMenuItems=(items:IMenuItem[], align:"RIGHT"|"LEFT"):React.ReactElement => {
        const content:any=[];
        items.forEach((item:IMenuItem) => {
            if (item.type==="BUTTON" && item.align===align) {
                const props:any=wrapPath({color: "inherit", key: `${item.key}-index`}, item.path);
                content.push(<Button {...props}>{item.label}</Button>);
            } else if (item.type==="ICON_BUTTON" && item.align===align) {
                content.push(
                    <IconButton
                        key={`${item.key}-index`}
                        aria-label={`${item.label}`}
                        aria-controls={`${item.menuId}`}
                        onClick={onMenuClickMethod(item.key.toLowerCase(), useOpen)}
                        aria-haspopup="true"
                        color="inherit"
                    >
                        {item.avatar}
                    </IconButton>,
                );
            }
        });
        return content;
    };

    /**
     * constructMenu
     * @return {React.ReactElement|null}
     */
    const constructMenu=():React.ReactElement|null => {
        if (menuBarProps.auth.isLoading) return (null);
        const items:IMenuItem[]=menuItems({onMenuClickMethod, dispatch, user: menuBarProps.auth.user, signOut: UserService.signOut});
        return (
            <>
                {/* push items to left */}
                <Box sx={{flexGrow: 1, display: {xs: "none", md: "flex"}}}>
                    {constructMenuItems(items, "LEFT")}
                </Box>
                {/* push items to right */}
                <Box sx={{flexGrow: 1, display: {xs: "flex", md: "none"}}} />
                <Box sx={{display: {xs: "none", md: "flex"}}}>
                    {constructMenuItems(items, "RIGHT")}
                </Box>

                {/* mobile MenuIcon button */}
                <Box sx={{display: {xs: "flex", md: "none"}}}>
                    <IconButton
                        aria-label="show more"
                        aria-haspopup="true"
                        onClick={onMenuClickMethod("mobile", useOpen)}
                        color="inherit"
                    >
                        <MenuIcon />
                    </IconButton>
                    {constructProfileMenu((items.filter((item:IMenuItem) => item.key==="PROFILE"))[0])}
                    {constructMobileMenu(items)}
                </Box>
            </>
        );
    };

    /**
     * constructAppBar
     * @return {React.ReactElement}
     */
    const constructAppBar=():React.ReactElement => {
        const isHome=location.pathname===APP_PATHS.HOME;
        return (
            <MuiAppBar position={!isHome?"fixed":"absolute"} sx={isHome?{backgroundColor: "#00000000", boxShadow: "none"}:undefined}>
                <Toolbar variant="dense">
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href={APP_PATHS.HOME}
                        sx={{
                            mr: 2,
                            display: {xs: "flex", md: "flex"},
                            // fontFamily: "monospace",
                            fontWeight: 600,
                            letterSpacing: ".1rem",
                            color: "inherit",
                            textDecoration: "none",
                        }}
                    >
                        Haidarzxc
                    </Typography>
                    {constructMenu()}
                </Toolbar>
            </MuiAppBar>
        );
    };

    /**
     * constructLayout
     * @return {React.ReactElement}
     */
    const constructLayout=():React.ReactElement => (
        <Box sx={{display: "flex"}}>
            <CssBaseline />
            {constructAppBar()}
        </Box>
    );

    return constructLayout();
}

export default MenuBar;
