import React from "react";

/**
 * Http404
 * @return {React.ReactElement}
 */
function Http404():React.ReactElement {
    return <div>Http404</div>;
}

export default Http404;
