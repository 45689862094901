import React from "react";
import {Storage, Apps, LaptopMac, Instagram, LinkedIn, GitHub} from "@mui/icons-material";
import {TierCardProps, TagProps, DegreeCardProps} from "../views/About";
import AUIS_DEGREE from "../assets/auis_degree.png";
import CCNY_DEGREE from "../assets/ccny_degree.png";

const TIERS_ICON_STYLE={height: "1em", fontSize: {xs: "4.0em", sm: "6.6em", md: "6.6em"}};
const SOCIAL_MEDIA_ICON_STYLE={fontSize: "35px"};

export const SOCIAL_MEDIA=[
    {icon: <Instagram sx={SOCIAL_MEDIA_ICON_STYLE} />, url: "https://www.instagram.com/savage_lionel/"},
    {icon: <LinkedIn sx={SOCIAL_MEDIA_ICON_STYLE} />, url: "https://www.linkedin.com/in/haidaralanbari/"},
    {icon: <GitHub sx={SOCIAL_MEDIA_ICON_STYLE} />, url: "https://github.com/haidarzxc"},
];

export const TIERS:TierCardProps[]=[
    {label: "Frontend", icon: <LaptopMac sx={TIERS_ICON_STYLE} />},
    {label: "Backend", icon: <Apps sx={TIERS_ICON_STYLE} />},
    {label: "Data", icon: <Storage sx={TIERS_ICON_STYLE} />},
];

export const DEGREES:DegreeCardProps[]=[
    {img: CCNY_DEGREE, label: "City College of New York (CCNY)", url: "https://www.ccny.cuny.edu/"},
    {img: AUIS_DEGREE, label: "American University of Iraq, Sulaimani (AUIS)", url: "https://auis.edu.krd/"},
];

export const OVERVIEW_CONTENT=`I am a 3-Tier software engineer with extensive knowledge in JavaScript and TypeScript programming 
                        languages. I have profound experience in Reactjs, Google Material UI, Nodejs and a wide range of 
                        Front-end frameworks and libraries. I enjoy collaborating on all stack phases starting from UI/UX 
                        wireframing, Graphical User Interface (GUI) design, Application Programming Interfaces (APIs) 
                        implementation to data tier management and storage. I am self-driven and continuously working on 
                        improving my skills through problem solving and real-world application development.`;

export const EXPERIENCE_CONTENT=`I joined the Research Foundation CUNY - Building Performance Lab (BPL) in 2017 as an intern (Part-Time) 
                        and later in 2019 became a (Full-Time) Full-Stack Engineer. I am in charge of developing software applications 
                        to aid in analysis of building energy data for NYC-sponsored research clients. Furthermore, I collaborate with 
                        internal staff to design and implement new features and tools to fit the evolving needs of client's scope. 
                        I also mentor junior developers and/or undergraduate interns by providing peer code reviews and programming 
                        sessions in efforts to improve code quality and design in terms of runtime and space complexity.`;

export const EDUCATION_CONTENT=`I hold two degrees, Master of Science in Computer Science from City University of New York (CUNY) - 
                                City College of New York (CCNY) and Bachelor of Science in Information Technology from American 
                                University of Iraq, Sulaimani (AUIS)`;

export const TAGS:TagProps[]=[
    {tag: "#React", url: "https://react.dev/"},
    {tag: "#TypeScript", url: "https://www.typescriptlang.org/"},
    {tag: "#JavaScript", url: "https://developer.mozilla.org/en-US/docs/Web/JavaScript"},
    {tag: "#Python", url: "https://www.python.org/"},
    {tag: "#Java", url: "https://www.oracle.com/java/technologies/downloads/"},
    {tag: "#C#", url: "https://learn.microsoft.com/en-us/dotnet/csharp/"},
    {tag: "#Nodejs", url: "https://nodejs.org/en"},
    {tag: "#Git", url: "https://git-scm.com/"},
    {tag: "#Redux", url: "https://redux.js.org/"},
    {tag: "#Html", url: "https://developer.mozilla.org/en-US/docs/Web/HTML"},
    {tag: "#CSS", url: "https://developer.mozilla.org/en-US/docs/Web/CSS"},
    {tag: "WebSocket API", url: "https://developer.mozilla.org/en-US/docs/Web/API/WebSockets_API"},
    {tag: "#Expressjs", url: "https://expressjs.com/"},
    {tag: "#JQuery", url: "https://jquery.com/"},
    {tag: "#Webpack", url: "https://webpack.js.org/"},
    {tag: "#Vitejs", url: "https://vitejs.dev/"},
    {tag: "#Threejs", url: "https://threejs.org/"},
    {tag: "#Material", url: "https://mui.com/"},
    {tag: "#syncfusion", url: "https://www.syncfusion.com/"},
    {tag: "#Ant", url: "https://ant.design/"},
    {tag: "#Chakra", url: "https://chakra-ui.com/"},
    {tag: "#Bootstrap", url: "https://getbootstrap.com/"},
    {tag: "#Jest", url: "https://jestjs.io/"},
    {tag: "#Auth0", url: "https://auth0.com/"},
    {tag: "#Firebase", url: "https://firebase.google.com/"},
    {tag: "#FramerMotion", url: "https://www.framer.com/motion/"},
    {tag: "#Mapbox", url: "https://www.mapbox.com/"},
    {tag: "#Postgresql", url: "https://www.postgresql.org/"},
    {tag: "#Tailwind", url: "https://tailwindcss.com/"},
    {tag: "#D3", url: "https://d3js.org/"},
    {tag: "#Django", url: "https://www.djangoproject.com/"},
    {tag: "#ESlint", url: "https://eslint.org/"},
    {tag: "#npm", url: "https://www.npmjs.com/"},
    {tag: "#Fastapi", url: "https://fastapi.tiangolo.com/"},
    {tag: "#Docker", url: "https://www.docker.com/"},
    {tag: "#Digitalocean", url: "https://www.digitalocean.com/"},
    {tag: "#Heroku", url: "https://www.heroku.com/"},
    {tag: "#AWS", url: "https://aws.amazon.com/"},
    {tag: "#Echarts", url: "https://echarts.apache.org/en/index.html"},
    {tag: "#Nivo", url: "https://nivo.rocks/"},
    {tag: "#Airbnb", url: "https://airbnb.io/visx"},
    {tag: "#react-vis", url: "https://uber.github.io/react-vis/"},
    {tag: "#Viser", url: "https://viserjs.github.io/"},
];
