import React, {useCallback, useEffect, useMemo} from "react";
import {useForm as useFormBase, RegisterOptions, SubmitHandler, Controller} from "react-hook-form";
import {joiResolver} from "@hookform/resolvers/joi";
import Joi from "joi";
import {Timestamp} from "firebase/firestore";
import {useSelector} from "react-redux";
import {DialogId, FormSchema, CheckListItem} from "../../store/types/confType";
import {TextField, Button, Autocomplete, UploadField, DatePicker, CheckList} from "./Inputs";

export interface IForm {
    fields: React.ReactElement[]
    withForm: (layout:React.ReactElement, onSubmit:SubmitHandler<any>) => React.ReactElement
    onReset: () => void
}

/**
 *  const FORM_SCHEMA_EXAMPLE:FormSchema[]=[
 *      {
 *          id: "<ID>",
 *          type: "text"|"password"|"submit"|"reset"|"autocomplete"|"datepicker"|"file",
 *          label: "<LABEL>",
 *          joiOptions: <Joi>
 *      },
 *  ];
 *      Joi examples
 *          Joi.string().email({tlds: {allow: false}}).lowercase().required()
 *              .messages({
 *                  "string.empty": "Required",
 *                  "string.email": "valid email",
 *               }),
 *          joiOptions: Joi.string().required()
 *              .messages({
 *                  "any.required": "Required",
 *                   "string.base": "Select a meal",
 *               }),
 *           joiOptions: Joi.array().min(1).items(Joi.string()).required()
 *               .messages({
 *                   "any.required": "Required",
 *                   "array.min": "Select at least 1 item",
 *               }),
 *           joiOptions: Joi.array().min(1).max(1).items()
 *               .required()
 *               .messages({
 *                   "any.required": "Required",
 *                   "array.min": "Upload a File",
 *                   "array.max": "max 1",
 *               }),
 *           joiOptions: Joi.array().min(1).max(2).items()
 *               .required()
 *               .messages({
 *                   "any.required": "Required",
 *                   "array.min": "upload at least 1 item",
 *                   "array.max": "max 2",
 *               }),
 *           joiOptions: Joi.date().required()
 *                .messages({
 *                   "any.required": "Required",
 *                   "date.base": "Invalid Date",
 *               }),
 * useForm
 * @param {FormSchema[]} schema
 * @return {IForm}
 */
function useForm(schema:FormSchema[], currentRow?:any):any {
    const dialogId:DialogId=useSelector((state:any) => state.conf.dialogId);
    // extract Joi schema rules
    const keys:any={};
    const row:any=useMemo(() => {
        const obj:any={...currentRow};
        delete obj.id;
        // remove comment from current row copy
        if (obj.comment) delete obj.comment;
        // parse TimeStamp to Date
        if (obj?.name instanceof Timestamp) obj.name=obj.name.toDate();
        if (typeof obj?.imageURL ==="string") obj.imageURL=[];
        return obj;
    }, [currentRow]);

    schema.forEach((field:FormSchema) => {
        if (field.joiOptions) keys[field.id]=field.joiOptions;
    });

    // setup form config
    const {register, handleSubmit, formState: {errors}, reset, control}:any= useFormBase({
        mode: "onChange",
        resolver: joiResolver(Joi.object(keys)),
    });

    useEffect(() => {
        reset(row);
    }, [reset, row]);

    /**
     * onReset
     * @return {void}
     */
    const onReset=useCallback(() => {
        reset(undefined, {
            keepErrors: false,
            keepDirty: false,
            keepDirtyValues: false,
            keepValues: false,
            keepDefaultValues: false,
            keepTouched: false,
            keepIsValid: false,
        });
    }, [reset]);

    // on CREATE dialog; reset form
    useEffect(() => (dialogId.includes("CREATE")?onReset():undefined), [dialogId, onReset]);

    /**
     * getComponent
     * @param {FormSchema} schema
     * @return {React.ReactElement|null}
     */
    const getComponent=(formSchema:FormSchema):React.ReactElement|null => {
        let reg:any=null;

        if (!["submit", "reset"].includes(formSchema.type)) reg=register(formSchema.id, formSchema.registerOptions as RegisterOptions);

        switch (formSchema.type) {
            case "password":
            case "text": return (
                <TextField
                    key={formSchema.id}
                    helperText={errors[formSchema.id]?.message?errors[formSchema.id].message:" "}
                    error={errors[formSchema.id]?.message!==undefined}
                    register={reg}
                    type={formSchema.type}
                    id={formSchema.id}
                    label={formSchema.label}
                    {...formSchema.textAreaOptions}
                    {...(formSchema.textAreaOptions && {multiline: true})}
                />
            );
            case "autocomplete": {
                if (!formSchema.autocompleteOptions) return null;
                return (
                    <Controller
                        key={formSchema.id}
                        name={formSchema.id}
                        defaultValue={formSchema.autocompleteOptions.multiple?[]:""}
                        control={control}
                        render={
                            ({field: {onChange, value}}) => {
                                const val=(formSchema.autocompleteOptions?.multiple || currentRow)?value:undefined;
                                return (
                                    <Autocomplete
                                        onChange={(_:any, data:any) => onChange(data)}
                                        value={val}
                                        options={formSchema.autocompleteOptions?.options||[]}
                                        multiple={formSchema.autocompleteOptions?.multiple}
                                        renderInput={{error: errors[formSchema.id]?.message, label: formSchema.label}}
                                    />
                                );
                            }
                        }
                    />
                );
            }
            case "file": {
                if (!formSchema.autocompleteOptions) return null;
                return (
                    <Controller
                        key={formSchema.id}
                        name={formSchema.id}
                        defaultValue={[]}
                        control={control}
                        render={
                            ({field: {onChange, value}}) => (
                                <UploadField
                                    id={`${formSchema.id}-file-upload`}
                                    onChange={(_:any, data:any) => {
                                        const toArray=data?Array.from(data):[];
                                        onChange(toArray);
                                    }}
                                    value={value}
                                    options={value}
                                    multiple={formSchema.autocompleteOptions?.multiple}
                                    renderInput={{error: errors[formSchema.id]?.message, label: formSchema.label}}
                                />
                            )
                        }
                    />
                );
            }
            case "datepicker": {
                return (
                    <Controller
                        key={formSchema.id}
                        name={formSchema.id}
                        defaultValue={null}
                        control={control}
                        render={
                            ({field: {onChange, value}}) => (
                                <DatePicker
                                    onChange={(_:any, data:any) => onChange(_)}
                                    value={value}
                                    label={formSchema.label}
                                    slotProps={{textField: {error: errors[formSchema.id]?.message?true:false as boolean, helperText: errors[formSchema.id]?.message||" "}}}
                                />
                            )
                        }
                    />
                );
            }
            case "checklist": {
                if (!formSchema.checkListOptions) return null;
                return (
                    <Controller
                        key={formSchema.id}
                        name={formSchema.id}
                        defaultValue={[]}
                        control={control}
                        render={
                            ({field: {onChange, value}}) => (
                                <CheckList
                                    id={formSchema.id}
                                    label={formSchema.label}
                                    options={formSchema.checkListOptions?.options||[]}
                                    itemKeys={formSchema.checkListOptions?.itemKeys||[]}
                                    helperText={errors[formSchema.id]?.message?errors[formSchema.id].message:" "}
                                    error={errors[formSchema.id]?.message!==undefined}
                                    value={value}
                                    onChange={(item:CheckListItem, override?:boolean) => (_:any) => {
                                        let newValue=[...value];
                                        const isIncluded=newValue.find((chkItem:CheckListItem) => chkItem.key===item.key);
                                        if (isIncluded) newValue=newValue.filter((chkItem:CheckListItem) => chkItem.key!==item.key);
                                        if (!isIncluded || override) newValue.push(item);
                                        onChange(newValue);
                                    }}
                                />
                            )
                        }
                    />
                );
            }
            case "reset":
            case "submit": return (
                <Button
                    key={formSchema.id}
                    onClick={formSchema.type==="reset"?onReset:undefined}
                    id={formSchema.id}
                    type={formSchema.type}
                    label={formSchema.label}
                    variant="outlined"
                />
            );
            default: return null;
        }
    };

    /**
     * construct
     * @param {FormSchema[]} fields
     * @return {React.ReactElement[]}
     */
    const construct=(formSchema:FormSchema[]):React.ReactElement[] => {
        const inputs:React.ReactElement[]=[];
        formSchema.forEach((field:FormSchema) => {
            const input=getComponent(field);
            if (input) inputs.push(input);
            else console.log("Error: unhandled component useForm()");
        });
        return inputs;
    };

    /**
     * withForm
     * @param {React.ReactElement} layout
     * @param {SubmitHandler<any>} onSubmit
     * @return {React.ReactElement}
     */
    const withForm=(layout:React.ReactElement, onSubmit:SubmitHandler<any>):React.ReactElement => (
        <form onSubmit={handleSubmit(onSubmit)}>
            {layout}
        </form>
    );
    return {fields: construct(schema), withForm, onReset};
}

export default useForm;
