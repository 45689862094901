import React, {useState} from "react";
import {Grid} from "@mui/material/";
import {Descendant} from "slate";
import Dialog from "../generics/Dialog";
import {DialogId} from "../../store/types/confType";
import RichText from "../generics/RichText";

interface CommentProps{
    onSubmit:(data:any) => void
    currentRow:any
    dialogId: DialogId
    label: string
    isMD:boolean
    isLoading:boolean
}

/**
 * Comment
 * @return {React.ReactElement}
 */
function Comment(props:CommentProps):React.ReactElement {
    const [content, setContent]=useState<Descendant[]>([]);

    /**
     * constructLayout
     * @return {React.ReactElement}
     */
    const constructLayout=():React.ReactElement => (
        <Dialog
            id={props.dialogId}
            label={props.label}
            onPrimaryAction={(args:any) => {
                props.onSubmit(content);
                setContent([]);
            }}
            isLoading={props.isLoading}
            onPrimaryLabel="Add"
            onCloseAction={() => setContent([])}
            content={(
                <Grid container direction="row" justifyContent="center" alignItems="center">
                    <Grid item xs={12}><RichText currentRow={props.currentRow} isMD={props.isMD} onChange={(value:Descendant[]) => setContent(value)} /></Grid>
                </Grid>
            )}
        />
    );
    return constructLayout();
}

export default Comment;
