import React, {Dispatch, useEffect, useMemo, useState, SetStateAction, useRef, useCallback} from "react";
import {useTheme, Theme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
    Grid,
    Button,
    ButtonGroup,
    Popper,
    Grow,
    Paper,
    ClickAwayListener,
    MenuList,
    MenuItem,
} from "@mui/material/";
import {useDispatch, useSelector} from "react-redux";
import {UnknownAction} from "redux";
import {FieldValues} from "react-hook-form";
// icons
import {
    Assignment,
    Assessment,
    Difference,
    DeleteForever,
    Edit,
    Add,
    ArrowDropDown,
    Comment,
} from "@mui/icons-material";
import {grey} from "@mui/material/colors";
// components
import Tab, {TabProp} from "../components/generics/Tab";
import SpeedDial from "../components/generics/SpeedDial";
import LogForm, {FORM_SCHEMA as LOG_FORM_SCHEMA} from "../components/forms/Log";
import {FORM_SCHEMA as DIET_FORM_SCHEMA} from "../components/forms/Diet";
import {FORM_SCHEMA as WORKOUT_FORM_SCHEMA} from "../components/forms/Workout";
import CommentForm from "../components/forms/Comment";
import RangeForm from "../components/forms/Range";
import RoutineListView from "../components/layout/RoutineListView";
import RoutineReportView from "../components/layout/RoutineReportView";
import DeleteForm from "../components/forms/Delete";
import {BaseService, Util} from "../services/firebase";
import {isDateFalls, logFilterHandler} from "../handler/firebase";
import {DialogId, ActionType} from "../store/types/confType";
import {COLLECTIONS} from "../config";
import Box from "../components/generics/Box";

/**
 * Routine
 * @return {React.ReactElement}
 */
function Routine():React.ReactElement {
    const theme:Theme=useTheme();
    const isMD:boolean=useMediaQuery(theme.breakpoints.down("md"));
    const isSM:boolean=useMediaQuery(theme.breakpoints.down("sm"));
    const dispatch:Dispatch<UnknownAction>=useDispatch();
    const currentRow:any=useSelector((state:any) => state.conf.currentRow);
    const [tabIndex, setTabIndex]:[number, Dispatch<SetStateAction<number>>]=useState(0);
    const [collection, setCollection]:[string, Dispatch<SetStateAction<string>>]=useState(COLLECTIONS.LOG);
    const [logs, setLogs]:[any, Dispatch<SetStateAction<any>>]=useState(null);
    const [workouts, setWorkouts]:[any, Dispatch<SetStateAction<any>>]=useState(null);
    const [diets, setDiets]:[any, Dispatch<SetStateAction<any>>]=useState(null);
    const [filterAction, setFilterAction]:[ActionType, Dispatch<SetStateAction<ActionType>>]=useState<ActionType>("this-year");
    const filterActionAnchorRef=useRef<HTMLDivElement>(null);
    const [filterActionOpen, setFilterActionOpen]:[boolean, Dispatch<SetStateAction<boolean>>]=useState(false);
    const [isLoading, setIsLoading]:[boolean, Dispatch<SetStateAction<boolean>>]=useState(false);
    const [range, setRange]:[any, Dispatch<SetStateAction<any>>]=useState<any>(null);

    const GET_CALLS_PROPS=useMemo(() => (
        [
            {collection: COLLECTIONS.LOG, set: setLogs, formSchema: LOG_FORM_SCHEMA, filterAction},
            {collection: COLLECTIONS.WORKOUT, current: workouts, set: setWorkouts, formSchema: WORKOUT_FORM_SCHEMA},
            {collection: COLLECTIONS.DIET, current: diets, set: setDiets, formSchema: DIET_FORM_SCHEMA},
        ]
    ), [filterAction, workouts, diets]);

    /**
     * onTabChange
     * @param {any} args
     * @return {void}
     */
    const onTabChange=(index:number):void => {
        if (index===0) setCollection(COLLECTIONS.LOG);
        else if (index===1) setCollection("REPORT");
        dispatch({type: "@@CONF/SET_CURRENT_ROW", currentRow: null});
        setTabIndex(index);
    };

    /**
     * resolveFilterAction
     * @param {ActionType} action
     * @return {void}
     */
    const resolveFilterAction=(action:ActionType) => (args:React.MouseEvent<HTMLElement>):void => {
        // escape if same action
        if (action===filterAction) return;
        setFilterAction(action);
        setLogs(null);
        setRange(null);
    };

    /**
     * onRangeDialogOpen
     * @param {React.MouseEvent<HTMLElement>} args
     * @return {void}
     */
    const onRangeDialogOpen=(args:React.MouseEvent<HTMLElement>):void => {
        dispatch({type: "@@CONF/SET_DIALOG_ID", dialogId: `${collection.toUpperCase()}_RANGE`});
        setFilterAction("range");
    };

    /**
     * onRangeDialogClose
     * @return {void}
     */
    const onRangeDialogClose=():void => {
        if (!range) setFilterAction("this-year");
    };

    const getDocs=useCallback(async () => {
        GET_CALLS_PROPS.forEach(async (callProp:any) => {
            // fetch workouts and diets once
            if (callProp.current) return;
            const res= await BaseService.list(callProp);
            if (res.error) {
                // display snack message
                dispatch({
                    type: "@@CONF/NOTIFIER_ENQUEUE",
                    notification: {
                        message: res.error.message,
                        options: {variant: "error", action: "DISMISS", persist: false},
                    },
                });
            }
        });
    }, [dispatch, GET_CALLS_PROPS]);

    useEffect(() => {
        getDocs();
    }, [getDocs]);

    // reseting current row
    useEffect(() => (
        () => {
            dispatch({type: "@@CONF/SET_CURRENT_ROW", currentRow: null, dialogId: "NONE"});
        }
    ), [dispatch]);

    const actions:any= useMemo(() => (
        [
            {icon: <Add />, name: "Add", onClick: (args:React.MouseEvent<HTMLElement>) => dispatch({type: "@@CONF/SET_DIALOG_ID", dialogId: `${collection.toUpperCase()}_CREATE`})},
            {icon: <Edit />, name: "Edit", onClick: currentRow && isDateFalls(currentRow?.name)?((args:React.MouseEvent<HTMLElement>) => dispatch({type: "@@CONF/SET_DIALOG_ID", dialogId: `${collection.toUpperCase()}_UPDATE`})):null},
            {icon: <DeleteForever />, name: "Delete", onClick: currentRow && isDateFalls(currentRow?.name)?((args:React.MouseEvent<HTMLElement>) => dispatch({type: "@@CONF/SET_DIALOG_ID", dialogId: `${collection.toUpperCase()}_DELETE`})):null},
            {icon: <Comment />, name: "Coment", onClick: currentRow && ((args:React.MouseEvent<HTMLElement>) => dispatch({type: "@@CONF/SET_DIALOG_ID", dialogId: `${collection.toUpperCase()}_COMMENT`}))},
        ]
    ), [collection, currentRow, dispatch]);

    const filterActions=[
        {id: "add", isMD, color: "error", label: "ADD", onClick: actions[0].onClick},
        {id: "range", label: "RANGE", onClick: onRangeDialogOpen},
        {id: "all", label: "ALL", onClick: undefined},
        {id: "this-year", label: "THIS YEAR", onClick: undefined},
        {id: "last-year", label: "LAST YEAR", onClick: undefined},
    ];

    // assign resolveFilterAction to filter actions
    filterActions.slice(2).forEach((item:any) => {
        /* eslint-disable no-param-reassign */
        item.onClick=resolveFilterAction(item.id);
    });

    /**
     * onCall
     * @param {FieldValues} data
     * @return {Promise<void>}
     */
    const onCall= (type:DialogId) => async (data:FieldValues):Promise<void> => {
        setIsLoading(true);
        let res:any=null;
        // resolve service CRUD
        const serviceMapper:any={
            LOG_CREATE: () => BaseService.post({collection, payload: data}),
            LOG_UPDATE: () => BaseService.update({collection, payload: data, currentRow}),
            LOG_DELETE: () => BaseService.del({collection, currentRow}),
            LOG_COMMENT: () => BaseService.setComment({collection, payload: data, currentRow}),
            LOG_RANGE: () => BaseService.list({payload: data, ...GET_CALLS_PROPS[0]}),
        };
        // call service
        res=await serviceMapper[type]();

        if (res.data) {
            // close dialog
            dispatch({type: "@@CONF/SET_DIALOG_ID", dialogId: "NONE"});
            // display snack message
            dispatch({
                type: "@@CONF/NOTIFIER_ENQUEUE",
                notification: {
                    message: res.data.message,
                    options: {variant: "success", action: "DISMISS", persist: false},
                },
            });
            // unset selected row
            if (currentRow) dispatch({type: "@@CONF/SET_CURRENT_ROW", currentRow: null});

            // re-fetch values to update grid view
            if (tabIndex===0 && type!=="LOG_RANGE") await BaseService.list({payload: range, ...GET_CALLS_PROPS[0]});
            // setting date range in case a CRUD is done on listed range
            // that way, when view re-lists ONLY range
            if (filterAction==="range" && type==="LOG_RANGE") setRange(data);
        } else if (res.error) {
            // display snack message
            dispatch({
                type: "@@CONF/NOTIFIER_ENQUEUE",
                notification: {
                    message: res.error.message,
                    options: {variant: "error", action: "DISMISS", persist: false},
                },
            });
        }
        setIsLoading(false);
    };

    /**
     * onFilterClick
     * @param {any} call
     * @param {ActionType} value
     * @return {void}
     */
    const onFilterClick=(call:any, value:ActionType) => (args:React.MouseEvent<HTMLElement>):void => {
        if (value!==filterActions[0].id) setFilterAction(value);
        setFilterActionOpen(false);
        call();
    };

    /**
     * onMobileFilterArrowClick
     * @param {React.MouseEvent<HTMLElement>} args
     * @return {void}
     */
    const onMobileFilterArrowClick=(args:React.MouseEvent<HTMLElement>):void => {
        setFilterActionOpen((prevOpen:any) => !prevOpen);
    };

    /**
     * onFilterClickAway
     * @param {Event} args
     * @return {void}
     */
    const onFilterClickAway=(args:Event):void => {
        if (filterActionAnchorRef?.current?.contains(args.target as HTMLElement)) return;
        setFilterActionOpen(false);
    };

    /**
     * constructController
     * @return {React.ReactElement}
     */
    const constructController=():React.ReactElement => (
        <Box>
            {!isSM && (
                <ButtonGroup variant="contained" fullWidth size="small">
                    {filterActions.map((item:any) => {
                        if (item.isMD) return null;
                        return (<Button variant={filterAction===item.id?"outlined":"contained"} key={item.id} color={item.color || "primary"} onClick={onFilterClick(item.onClick, item.id)}>{item.label}</Button>);
                    })}
                </ButtonGroup>
            )}
            {isSM && (
                <Box sx={{position: "relative"}}>
                    <ButtonGroup variant="contained" ref={filterActionAnchorRef} fullWidth size="small">
                        <Button>{(filterActions.filter((item:any) => item.id===filterAction))[0].label}</Button>
                        <Button
                            sx={{maxWidth: "45px", bgcolor: grey[800]}}
                            size="small"
                            onClick={onMobileFilterArrowClick}
                        >
                            <ArrowDropDown />
                        </Button>
                    </ButtonGroup>
                    <Popper
                        sx={{zIndex: 1, width: "100%", paddingLeft: "24px", paddingRight: "24px"}}
                        open={filterActionOpen}
                        anchorEl={filterActionAnchorRef.current}
                        role={undefined}
                        transition
                        disablePortal
                    >
                        {({TransitionProps, placement}) => (
                            <Grow {...TransitionProps} style={{transformOrigin: placement==="bottom"?"center top":"center bottom"}}>
                                <Paper>
                                    <ClickAwayListener onClickAway={onFilterClickAway}>
                                        <MenuList autoFocusItem>
                                            {filterActions.map((item:any) => {
                                                if (item.isMD) return null;
                                                return <MenuItem key={item.id} selected={filterAction===item.id} onClick={onFilterClick(item.onClick, item.id)}>{item.label}</MenuItem>;
                                            })}
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </Box>
            )}
        </Box>
    );

    const MemoizedRoutineListView=useMemo(() => (
        <RoutineListView
            data={logFilterHandler(logs?.rows)}
            currentRow={currentRow}
            actions={actions}
            isMD={isMD}
            isSM={isSM}
            isLoading={isLoading}
        />
    ), [actions, currentRow, isMD, isSM, logs?.rows, isLoading]);

    const tabs:TabProp[]=[
        {
            label: "Log",
            icon: <Assignment />,
            content: (
                <Box>
                    {constructController()}
                    {MemoizedRoutineListView}
                    {/* Create Dialog Instance */}
                    <LogForm isLoading={isLoading} onSubmit={onCall("LOG_CREATE")} label="Add Log" dialogId="LOG_CREATE" diets={diets} workouts={workouts} isSM={isSM} />
                    {/* Update Dialog Instance */}
                    {currentRow && <LogForm isLoading={isLoading} onSubmit={onCall("LOG_UPDATE")} label="Edit Log" currentRow={currentRow} dialogId="LOG_UPDATE" diets={diets} workouts={workouts} isSM={isSM} />}
                    {/* Delete Dialog Instance */}
                    {currentRow && <DeleteForm onDelete={onCall("LOG_DELETE")} label="Delete Log" currentRow={currentRow} dialogId="LOG_DELETE" />}
                    {/* Comment Dialog Instance */}
                    {currentRow && <CommentForm isLoading={isLoading} onSubmit={onCall("LOG_COMMENT")} label="Notes" currentRow={currentRow} dialogId="LOG_COMMENT" isMD={isMD} />}
                    {/* Range Dialog Instance */}
                    {filterAction==="range" &&<RangeForm onSubmit={onCall("LOG_RANGE")} currentRow={range} onClose={onRangeDialogClose} label="Refine" dialogId="LOG_RANGE" />}
                </Box>
            ),
        },
        {
            label: "Report",
            icon: <Assessment />,
            content: (
                <Box>
                    <RoutineReportView data={logFilterHandler(logs?.rows)} isMD={isMD} isSM={isSM} />
                </Box>
            ),
        },
    ];

    return (
        <Box>
            {isMD && (
                <>
                    {collection===COLLECTIONS.LOG && (<SpeedDial ariaLabel="log-controller" actions={actions} icon={<Difference />} />)}
                    <Tab onTabChange={onTabChange} tabIndex={tabIndex} data={tabs} sticky />
                </>
            )}
            {!isMD && (
                <Grid container spacing={4} direction="row" justifyContent="space-around" alignItems="flex-start">
                    <Grid item xs={12} sm={12} md={6} lg={5} xl={4}>{tabs[0].content}</Grid>
                    <Grid item xs={12} sm={12} md={6} lg={7} xl={8}>{tabs[1].content}</Grid>
                </Grid>
            )}
        </Box>
    );
}

export default Routine;
