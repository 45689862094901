import React from "react";
import {Grid} from "@mui/material/";
import Joi from "joi";
import useForm, {IForm} from "../generics/useForm";
import {FormSchema, DialogId} from "../../store/types/confType";
import Dialog from "../generics/Dialog";
import {validateCheckListItems} from "./Diet";

export const FORM_SCHEMA:FormSchema[]=[
    {
        id: "name",
        type: "text",
        label: "Name",
        joiOptions: Joi.string().required()
            .messages({
                "string.empty": "Set a Name",
            }),
    },
    {
        id: "exercises",
        type: "checklist",
        label: "Exercises",
        checkListOptions: {
            options: [],
            itemKeys: [{key: "sets", value: 4}, {key: "reps", value: 10}],
        },
        joiOptions: Joi
            .array()
            .min(1)
            .required()
            .custom(validateCheckListItems)
            .messages({
                "any.required": "Required",
                "array.min": "Select at least 1 item",
                "custom.invalid": "Set a number. Min 0. Max 1000",
            }),
    },
];

interface WorkoutProps{
    onSubmit:(data:any) => void
    currentRow?:any
    dialogId: DialogId
    label: string
    exercises: any
}

/**
 * Workout
 * @return {React.ReactElement}
 */
function Workout(props:WorkoutProps):React.ReactElement {
    // pre-populate exercises values in form
    FORM_SCHEMA.slice(1).forEach((item:any) => {
        /* eslint-disable no-param-reassign */
        const values=props[item.id as keyof WorkoutProps];
        if (item.autocompleteOptions && values) item.autocompleteOptions.options=values.rows.map((row:any) => row.name);
        if (item.checkListOptions && values) item.checkListOptions.options=values.rows;
    });

    const form:IForm=useForm(FORM_SCHEMA, props.currentRow);
    /**
     * constructLayout
     * @param {React.ReactElement[]} fields
     * @return {React.ReactElement}
     */
    const constructLayout=(fields:React.ReactElement[]):React.ReactElement => (

        <Dialog
            id={props.dialogId}
            label={props.label}
            onPrimaryAction={props.onSubmit}
            onPrimaryLabel={props.currentRow?"Edit":"Add"}
            form={form}
            content={(
                <Grid container spacing={0} direction="row" justifyContent="center" alignItems="center">
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid container rowSpacing={1} columnSpacing={{xs: 2, sm: 2, md: 2}}>
                            {fields.map((item:React.ReactElement) => <Grid item xs={12} key={item.key}>{item}</Grid>)}
                        </Grid>
                    </Grid>
                </Grid>
            )}
        />
    );
    return constructLayout(form.fields);
}

Workout.defaultProps={
    currentRow: undefined,
};

export default Workout;
