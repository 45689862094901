import React from "react";
import {UseFormRegister, FieldValues} from "react-hook-form";

// mui components
import {
    Chip,
    Checkbox,
    Divider,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ClickAwayListener,
    Popper,
    Input,
    InputAdornment,
    IconButton,
    AutocompleteCloseReason,
    styled,
    Button as ButtonBase,
    ButtonProps as ButtonPropsBase,
    Autocomplete as AutocompleteBase,
    AutocompleteProps as AutocompletePropsBase,
    TextField as TextFieldBase,
    TextFieldProps as TextFieldPropsBase,
    Paper,
} from "@mui/material";
import {
    CheckBox,
    CloudUpload,
    Close,
    CheckBoxOutlineBlank,
} from "@mui/icons-material";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFnsV3";
import {
    DatePicker as DatePickerBase,
    LocalizationProvider,
    DatePickerProps,
} from "@mui/x-date-pickers";
import Box from "./Box";

const PopperStyledComponent = styled(Popper)(({theme}) => ({
    border: `1px solid ${
        theme.palette.mode === "light" ? "rgba(149, 157, 165, 0.2)" : "rgb(1, 4, 9)"
    }`,
}));

interface AutocompleteProps extends Omit<AutocompletePropsBase<any, any, any, any>, "onChange" | "renderInput">{
    onChange: any
    renderInput: {
        error?: string
        label: string
    }
}

interface Register {
    register?: UseFormRegister<FieldValues>
}

interface ButtonProps extends ButtonPropsBase {
    label: string
}

type TextFieldProps=TextFieldPropsBase & Register;

/**
 * TextField
 * @param {TextFieldProps} props
 * @return {React.ReactElement}
 */
export function TextField({register, ...props}:TextFieldProps): React.ReactElement {
    return (
        <TextFieldBase
            sx={{width: "100%"}}
            {...register}
            {...props}
        />
    );
}

TextField.defaultProps = {
    register: undefined,
};

/**
 * Button
 * @param {ButtonProps} props
 * @return {React.ReactElement}
 */
export function Button({label, ...props}:ButtonProps): React.ReactElement {
    return (
        <ButtonBase
            sx={{width: "100%", marginTop: "6px", marginBottom: "6px"}}
            variant="outlined"
            {...props}
        >
            {label}
        </ButtonBase>
    );
}

/**
 * Autocomplete
 * @param {AutocompleteProps} props
 * @return {React.ReactElement}
 */
export function Autocomplete({...props}:AutocompleteProps): React.ReactElement {
    const [checkAll, setCheckAll] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    /**
     * handleClickAway
     * @param {any} args
     * @return {void}
     */
    const handleClickAway=(args:any):void => setOpen(false);

    /**
     * checkAllChange
     * @param {any} event
     * @return {void}
     */
    const checkAllChange=(event: any):void => {
        const value=!checkAll;
        if (value) props.onChange(event, [...props.options]);
        else props.onChange(event, []);
        setCheckAll(value);
    };

    /**
     * onChange
     * @param {React.ChangeEvent<HTMLInputElement>} event
     * @param {any} newValue
     * @param {string} reason
     * @return {void}
     */
    const onChange=(event:React.ChangeEvent<HTMLInputElement>, newValue:any, reason:string):void => {
        if (reason==="selectOption") {
            props.onChange(event, newValue);
        } else if (reason==="removeOption") {
            props.onChange(event, newValue);
            setCheckAll(false);
        } else if (reason==="clear") {
            props.onChange(event, []);
            setCheckAll(false);
        }
    };

    /**
     * renderTags
     * @param {string[]} values
     * @param {any} getTagProps
     * @return {React.ReactElement[]}
     */
    const renderTags=(values: readonly string[], getTagProps:any):React.ReactElement[] => {
        const tags=values.slice(0, 3).map((option:string, index:number) => (
            <Chip variant="outlined" label={option} {...getTagProps({index})} />
        ));
        if (values.length>3) tags.push(<span key="plus-span">{`+${values.slice(3, values.length).length}`}</span>);
        return (tags);
    };

    /**
     * renderOption
     * @param {any} optionProps
     * @param {string} option
     * @param {any} args
     * @return {React.ReactElement}
     */
    const renderOption=(optionProps:any, option:string, args:any):React.ReactElement => (
        <li {...optionProps} style={{padding: "0px"}}>
            <Checkbox
                icon={<CheckBoxOutlineBlank />}
                checkedIcon={<CheckBox />}
                sx={{marginRight: 2}}
                checked={args.selected}
            />
            {option}
        </li>
    );

    /**
     * popperComponent
     * Note:
     * newParam added due to following:
     * react_devtools_backend.js:4012 Warning: React does not recognize the `disablePortal` prop on a DOM element.
     * If you intentionally want it to appear in the DOM as a custom attribute, spell it as lowercase `disableportal` instead.
     * If you accidentally passed it from a parent component, remove it from the DOM element.
     * @param {any} param
     * @return {React.ReactElement}
     */
    const popperComponent=(param:any):React.ReactElement => {
        const newParam={...param};
        delete newParam.disablePortal;
        delete newParam.anchorEl;
        return (
            <PopperStyledComponent {...param}>
                <Box
                    sx={{
                        backgroundColor: "white",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                    }}
                >
                    <List dense sx={{width: "100%", padding: "0px"}}>
                        <ListItem disablePadding>
                            <ListItemButton role={undefined} onClick={checkAllChange} dense sx={{padding: "0px"}}>
                                <ListItemIcon>
                                    <Checkbox
                                        checked={checkAll}
                                        onChange={checkAllChange}
                                        id="check-all"
                                        sx={{marginRight: "8px"}}
                                        onMouseDown={(e) => e.preventDefault()}
                                    />
                                </ListItemIcon>
                                <ListItemText id="select-all" primary={checkAll?"Unselect All":"Select All"} />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Box>
                <Divider />
                <Box {...newParam} />
            </PopperStyledComponent>
        );
    };

    /**
     * renderInput
     * @param {TextFieldProps} params
     * @return {React.ReactElement}
     */
    const renderInput=(params:TextFieldProps):React.ReactElement => (
        <TextField
            {...params}
            helperText={props.renderInput.error?props.renderInput.error:" "}
            error={props.renderInput.error!==undefined}
            label={props.renderInput.label}
        />
    );
    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <Box>
                <AutocompleteBase
                    disableCloseOnSelect={props.multiple}
                    open={props.multiple?open:undefined}
                    onClose={props.multiple?(e: any, reason: AutocompleteCloseReason) => (reason==="escape"?setOpen(false):undefined):undefined}
                    onOpen={props.multiple?() => setOpen(true):undefined}
                    renderTags={props.multiple?renderTags:undefined}
                    renderOption={props.multiple?renderOption:undefined}
                    PopperComponent={props.multiple?popperComponent:undefined}
                    {...props}
                    onChange={props.multiple?onChange:props.onChange}
                    renderInput={renderInput}
                />
            </Box>
        </ClickAwayListener>
    );
}

/**
 * UploadField
 * @param {AutocompleteProps} props
 * @return {React.ReactElement}
 */
export function UploadField({...props}:AutocompleteProps):React.ReactElement {
    const [file, setFile]=React.useState("");
    /**
     * onFileChange
     * @param {React.ChangeEvent<HTMLInputElement>} args
     * @return {void}
     */
    const onFileChange=(args:React.ChangeEvent<HTMLInputElement>):void => {
        props.onChange(args, args.target.files);
        setFile(args.target.value);
    };

    /**
     * onChange
     * @param {React.SyntheticEvent<Element, Event>} args
     * @param {any[]} v
     * @return {void}
     */
    const onChange=(args:React.SyntheticEvent<Element, Event>, v:any[]):void => {
        props.onChange(args, v);
        setFile("");
    };

    /**
     * onClick
     * @param {React.MouseEvent<HTMLElement>} args
     * @return {void}
     */
    const onClick=(args:React.MouseEvent<HTMLElement>):void => {
        props.onChange(args, []);
        setFile("");
    };

    /**
     * endAdornmentMethod
     * @return {React.ReactElement}
     */
    const endAdornmentMethod=():React.ReactElement => (
        <InputAdornment position="end" sx={{height: "32px"}}>
            <label htmlFor={`${props.id}icon-button-file`}>
                <Input
                    onChange={onFileChange}
                    value={file}
                    inputProps={{multiple: props.multiple?props.multiple:false}}
                    style={{display: "none"}}
                    id={`${props.id}icon-button-file`}
                    type="file"
                />
                <IconButton size="small" aria-label="upload" component="span"><CloudUpload /></IconButton>
            </label>
            <IconButton size="small" onClick={onClick} aria-label="erase" component="span"><Close /></IconButton>
        </InputAdornment>
    );

    /**
     * renderInput
     * @param {TextFieldProps} params
     * @return {React.ReactElement}
     */
    const renderInput=(params:TextFieldProps):React.ReactElement => (
        <TextField
            {...params}
            helperText={props.renderInput.error?props.renderInput.error:" "}
            error={props.renderInput.error!==undefined}
            label={props.renderInput.label}
            InputProps={{
                ...params.InputProps,
                style: {paddingRight: "0px", minWidth: "220px"},
                endAdornment: endAdornmentMethod(),
            }}
            inputProps={{
                ...params.inputProps,
            }}
        />
    );

    /**
     * optionLabel
     * @param {any} option
     * @return {string}
     */
    const optionLabel=(option:any):string => option.name;

    /**
     * renderTags
     * @param {any} values
     * @param {any} getTagProps
     * @return {React.ReactElement[]}
     */
    const renderTags=(values:any, getTagProps:any):React.ReactElement[] => {
        const tags=values.slice(0, 3).map((item: any, index: number) => (
            <Chip variant="outlined" label={`${item.name.substring(0, 5)}...`} {...getTagProps({index})} />
        ));
        if (values.length>3) tags.push(<span key="plus-span">{`+${values.slice(3, values.length).length}`}</span>);
        return tags;
    };

    /**
     * renderOption
     * @param {any} optionProps
     * @param {string} option
     * @param {any} args
     * @return {React.ReactElement}
     */
    const renderOption=(optionProps:any, option:any, args:any):React.ReactElement => (
        <li {...optionProps}>
            <Box sx={{flexGrow: 1}}>{option.name}</Box>
            <Box sx={{paddingRight: "40px"}}>
                <Paper sx={{padding: "4px"}}><img id={`${option.name}`} alt={`${option.name}`} src={URL.createObjectURL(option)} width={100} /></Paper>
            </Box>
            <Box
                component={Close}
                sx={{opacity: 0.6, width: 18, height: 18}}
                style={{visibility: args.selected ?"visible":"hidden"}}
            />
        </li>
    );

    return (
        <AutocompleteBase
            disablePortal
            disableCloseOnSelect={props.multiple}
            getOptionLabel={optionLabel}
            renderTags={renderTags}
            renderOption={renderOption}
            {...props}
            multiple
            onChange={onChange}
            renderInput={renderInput}
        />
    );
}

/**
 * DatePicker
 * @param {DatePickerProps<Date>} props
 * @return {React.ReactElement}
 */
export function DatePicker({...props}:DatePickerProps<Date>):React.ReactElement {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePickerBase
                sx={{width: "100%"}}
                label={props.label}
                views={["year", "month", "day"]}
                {...props}
            />
        </LocalizationProvider>
    );
}

export {default as CheckList} from "./CheckList";
