import React from "react";
import {useSnackbar, SnackbarKey, OptionsObject} from "notistack";
import {useDispatch, useSelector} from "react-redux";
import Button from "@mui/material/Button";
import {Notification} from "../../store/types/confType";

/**
 * withDismiss
 * @param {any} dispatch
 * @param {Notification} notification
 * @returns {Notification}
 */
const withDismiss=(notification:Notification, dispatch:any):Notification => ({
    ...notification,
    options: {
        ...notification.options,
        action: (key:any) => (<Button sx={{color: "white"}} onClick={() => dispatch({type: "@@CONF/NOTIFIER_CLOSE", key: notification.key})}>Dismiss</Button>),
    },
});

let displayed:any=[];

/**
 * useNotifier
 * @return {void}
 */
const useNotifier=():void => {
    const dispatch=useDispatch();
    const notifications:Notification[]=useSelector((store:any) => store.conf.notifications);
    const {enqueueSnackbar, closeSnackbar}=useSnackbar();

    /**
     * storeDisplayed
     * @param {SnackbarKey} id
     * @return {void}
     */
    const storeDisplayed=(id:SnackbarKey):void => {
        displayed=[...displayed, id];
    };

    /**
     * removeDisplayed
     * @param {SnackbarKey} id
     * @return {void}
     */
    const removeDisplayed=(id:SnackbarKey):void => {
        displayed=[...displayed.filter((key:number) => id!==key)];
    };

    React.useEffect(() => {
        notifications.forEach((note:Notification) => {
            // dismiss snackbar using notistack
            if (note.dismissed) closeSnackbar(note.key);

            // do nothing if snackbar is already displayed
            if (displayed.includes(note.key)) return;

            let {options}:Notification=note;
            if (options.action==="DISMISS") options=withDismiss(note, dispatch).options;

            // display snackbar using notistack
            enqueueSnackbar(note.message, {
                key: note.key,
                ...options,
                onClose: (event:any, reason:any, mykey:any) => {
                    if (note.options.onClose) note.options.onClose(event, reason, mykey);
                },
                onExited: (event:any, mykey:any) => {
                    // remove this snackbar from redux store
                    dispatch({type: "@@CONF/NOTIFIER_REMOVE", key: mykey});
                    removeDisplayed(mykey);
                },
            });
            // keep track of snackbars that we've displayed
            storeDisplayed(note.key);
        });
    }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);
};

export default useNotifier;
