import React from "react";
// import {red, deepPurple, blue, green, orange} from "@mui/material/colors";
import {
    Grid,
    Divider,
    Paper,
    Skeleton,
} from "@mui/material/";
import PieChart, {RecordType as PieRecordType} from "./PieChart";
import WeightTimelineChart, {RecordType as TimelineRecordType} from "./WeightTimelineChart";
import Box from "../generics/Box";

interface RoutineReportViewProps{
    data:any
    isMD:boolean
    isSM:boolean
}

/**
 * RoutineReportView
 * @return {React.ReactElement}
 */
function RoutineReportView(props:RoutineReportViewProps):React.ReactElement {
    /**
     * constructWidget
     * @return {React.ReactElement}
     */
    const constructWidget=():React.ReactElement => {
        const workoutTotals:any={};
        const formattedWorkoutTotals:PieRecordType[]|undefined=props.data?[]:undefined;
        const formattedTimeline:TimelineRecordType[]|undefined=props.data?[]:undefined;
        const items:any={
            totalRecords: {label: "Total Records", value: props.data?.length},
            minWeight: {label: "Minimum Weight", value: Number.MAX_VALUE},
            maxWeight: {label: "Maximum Weight", value: Number.MIN_VALUE},
            avgWeight: {label: "Average Weight", value: 0},
            currentWeight: {label: "Current Weight", value: props.data && props.data[0]?.weight},
        };

        // resolve items
        props.data?.forEach((item:any) => {
            // resolve total per workout type
            let workoutName="none";
            if (item?.workout) workoutName=item.workout;
            if (workoutName in workoutTotals) workoutTotals[workoutName]+=1;
            else workoutTotals[workoutName]=1;

            // resolve minWeight
            if (item.weight && item.weight<items.minWeight.value) items.minWeight.value=item.weight;

            // resolve maxWeight
            if (item.weight && item.weight>items.maxWeight.value) items.maxWeight.value=item.weight;

            // resolve avgWeight count
            if (item.weight) items.avgWeight.value+=item.weight;

            // resolve timeline data
            if (item.weight) formattedTimeline?.push({key: item.name.toDate(), value: item.weight});
        });

        // resolve avgWeight
        if (props.data) items.avgWeight.value=Math.round((items.avgWeight.value/=(props.data.filter((item:any) => item.weight && !Number.isNaN(item.weight))).length)*10)/10;

        // format workoutTotals
        Object.keys(workoutTotals).forEach((key:string) => {
            formattedWorkoutTotals?.push({key, value: workoutTotals[key]});
        });

        return (
            <Box sx={{marginTop: "-16px"}}>
                {/* divider */}
                <Divider sx={{marginTop: "8px", marginBottom: "8px"}} flexItem><b>Summary</b></Divider>
                {/* totals */}
                <Grid spacing={1} container direction="row" justifyContent="space-evenly" alignItems="flex-start">
                    {Object.keys(items).map((key:any, index:number) => {
                        const content:any=[];
                        let {value}=items[key];
                        if (!items[key].value || items[key].value===Number.MAX_VALUE || items[key].value===Number.MIN_VALUE) value="None";

                        content.push(
                            <Grid key={key} item xs={6} sm={4} md={5} lg={3} xl={2}>
                                {props.data?(
                                    <Paper elevation={1}>
                                        <Grid container direction="column" justifyContent="space-between" alignItems="center">
                                            <Grid item>{items[key].label}</Grid>
                                            <Grid item><b>{value}</b></Grid>
                                        </Grid>
                                    </Paper>
                                ):<Skeleton sx={{marginTop: "-14px", marginBottom: "-14px"}} key={key} width="100%" height={80} />}
                            </Grid>,
                        );

                        return content;
                    })}
                </Grid>
                {/* divider */}
                <Divider sx={{marginTop: "8px", marginBottom: "8px"}} flexItem><b>Breakdown</b></Divider>
                {/* charts */}
                <Grid container spacing={props.data?0:3} direction="row" justifyContent="space-evenly" alignItems="flex-start">
                    <Grid item xs={12} sm={12} md={12} lg={7} xl={6}><PieChart data={formattedWorkoutTotals} isMD={props.isMD} isSM={props.isSM} /></Grid>
                    <Grid item xs={12} sm={12} md={12} lg={5} xl={6}><WeightTimelineChart avgWeight={items.avgWeight.value} data={formattedTimeline} isMD={props.isMD} isSM={props.isSM} /></Grid>
                </Grid>
            </Box>
        );
    };

    return (
        <Box>
            {constructWidget()}
        </Box>
    );
}

export default RoutineReportView;
