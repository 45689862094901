import React, {SetStateAction, Dispatch} from "react";
import Avatar from "@mui/material/Avatar";
import {deepOrange} from "@mui/material/colors";
import {UnknownAction} from "redux";
// icons
import {VpnKey, Lock, Restaurant, Article, FitnessCenter, Assessment} from "@mui/icons-material/";
import {APP_PATHS} from "../../config";
import {OpenStatus} from "./MenuBar";
import {Payload} from "../../store/types/confType";

export interface SubItem {
    path?:string
    label:string
    onClick:any
    icon:any
}

export interface IMenuItem {
    key:string
    label?:string
    type?:"BUTTON"| "ICON_BUTTON"
    path?:string
    avatar?:any
    menuId?:string
    subItems?:SubItem[]
    icon?:React.ReactElement
    align:"LEFT"|"RIGHT"
}

interface IProps {
    user: any
    dispatch: Dispatch<UnknownAction>
    onMenuClickMethod:(key: string, update: Dispatch<SetStateAction<OpenStatus>>) => (args: React.MouseEvent<HTMLElement>) => void
    signOut:() => Promise<Payload>
}

/**
 * menuItems
 * @param {IProps} props
 * @return {IMenuItem[]}
 */
const menuItems=(props:IProps):IMenuItem[] => (
    [
        {
            key: "ROUTINE",
            type: props.user?"BUTTON":undefined,
            label: "Routine",
            path: APP_PATHS.ROUTINE,
            icon: <Assessment />,
            align: "LEFT",
        },
        {
            key: "WORKOUT",
            type: props.user?"BUTTON":undefined,
            label: "Workout",
            path: APP_PATHS.WORKOUT,
            icon: <FitnessCenter />,
            align: "LEFT",
        },
        {
            key: "NUTRITION",
            type: props.user?"BUTTON":undefined,
            label: "Nutrition",
            path: APP_PATHS.NUTRITION,
            icon: <Restaurant />,
            align: "LEFT",
        },
        {
            key: "ABOUT",
            type: "BUTTON",
            label: "About",
            path: APP_PATHS.ABOUT,
            icon: <Article />,
            align: "RIGHT",
        },
        {
            key: "SIGNIN",
            type: props.user?undefined:"BUTTON",
            label: "Login",
            path: APP_PATHS.SIGNIN,
            icon: <VpnKey />,
            align: "RIGHT",
        },
        {
            key: "PROFILE",
            align: "RIGHT",
            type: props.user?"ICON_BUTTON":undefined,
            avatar: <Avatar src={`${props.user?props.user.avatar:null}`} sx={{bgcolor: deepOrange[500]}}>{props.user?props.user.username:null}</Avatar>,
            menuId: "user-menu-avatar-id",
            subItems: [
                // {
                //     path: `${APP_PATHS.PROFILE}`,
                //     label: "Profile",
                //     onClick: (key:string, update:Dispatch<SetStateAction<OpenStatus>>) => (args:React.MouseEvent<HTMLButtonElement>) => {
                //         props.onMenuClickMethod(key, update)(args);
                //     },
                //     icon: <AccountCircle />,
                // },
                {
                    path: `${APP_PATHS.HOME}`,
                    label: "Signout",
                    onClick: (key:string, update:Dispatch<SetStateAction<OpenStatus>>) => async (args:React.MouseEvent<HTMLButtonElement>) => {
                        props.onMenuClickMethod(key, update)(args);
                        props.dispatch({type: "@@CONF/SIGNOUT"});
                        const res:any=await props.signOut();
                        if (res.data) {
                            // display snack message
                            props.dispatch({
                                type: "@@CONF/NOTIFIER_ENQUEUE",
                                notification: {
                                    message: res.data.message,
                                    options: {variant: "success", action: "DISMISS", persist: false},
                                },
                            });
                        } else if (res.error) {
                            // display snack message
                            props.dispatch({
                                type: "@@CONF/NOTIFIER_ENQUEUE",
                                notification: {
                                    message: res.error.message,
                                    options: {variant: "error", action: "DISMISS", persist: false},
                                },
                            });
                        }
                    },
                    icon: <Lock />,
                },
            ],
        },
    ]
);

export default menuItems;
